import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ModalProvider } from './ModalContext';
import Search from "./components/Search";
import Settings from "./components/Settings";
import HowToUse from "./components/HowToUse";
import PlaylistPlayer from "./components/PlaylistPlayer";
import ShareSong from "./components/ShareSong";
import Share from "./components/Share";
import SharePage from "./components/SharePage";
import Radio from "./components/Radio";
import RadioMobile from "./components/RadioMobile";
import LoginModal from "./components/LoginModal";
import RegisterModal from "./components/RegisterModal";
import ResetPasswordModal from "./components/ResetPasswordModal";
import ResetPasswordPage from "./components/ResetPasswordPage";
import PlaylistPageWithPlayer from './components/PlaylistPageWithPlayer';
import SharedPlaylistPage from './components/SharedPlaylistPage';
import SharedFavoritePage from './components/SharedFavoritePage';
import FavoritePageWithPlayer from './components/FavoritePageWithPlayer';
import RidoPage from './components/RadioPage';
import RadioPageWithPlayer from './components/RadioPageWithPlayer';
import SettingsPage from './components/SettingsPage';
import AdminPage from './components/AdminPage';

export default function App() {
  return (
    <ModalProvider>
      <Router>
        <Routes>
          <Route path="/howtouse" element={<HowToUse />}></Route>
          <Route path="/settings" element={<Settings />}></Route>
          <Route path="/settingspage" element={<SettingsPage />}></Route>
          <Route path="/player" element={<PlaylistPlayer />}></Route>
          <Route path="/playerpage" element={<PlaylistPageWithPlayer />}></Route>
          <Route path="/sharesong" element={<ShareSong />}></Route>
          <Route path="/share" element={<Share />}></Route>
          <Route path="/sharepage" element={<SharePage />}></Route>
          <Route path="/favoritepage" element={<FavoritePageWithPlayer />}></Route>
          <Route path="/radio" element={<Radio />}></Route>
          <Route path="/radiopage" element={<RidoPage />}></Route>
          <Route path="/radioeditpage" element={<RadioPageWithPlayer />}></Route>
          <Route path="/radiomobile" element={<RadioMobile />}></Route>
          <Route path="/reset-password" element={<ResetPasswordPage />}></Route>
          <Route path="/adminpage" element={<AdminPage />}></Route>
          <Route path="//sharedplaylist/:playlistId" element={<SharedPlaylistPage />}></Route>
          <Route path="//sharedfavorite/:playlistId" element={<SharedFavoritePage />}></Route>
          <Route path="/home" element={<Search />}></Route>
          <Route path="/" element={<Search />}></Route>
        </Routes>
      </Router>
      <LoginModal />
      <RegisterModal />
      <ResetPasswordModal />
    </ModalProvider>
  );
}


