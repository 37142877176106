import React, { createContext, useState, useContext } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isLoginModalVisible, setLoginModalVisible] = useState(false);
  const [isRegisterModalVisible, setRegisterModalVisible] = useState(false);
  const [isResetPasswordModalVisible, setResetPasswordModalVisible] = useState(false);

  return (
    <ModalContext.Provider value={{
      isLoginModalVisible,
      setLoginModalVisible,
      isRegisterModalVisible,
      setRegisterModalVisible,
      isResetPasswordModalVisible,
      setResetPasswordModalVisible
    }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
