import React, { useEffect, useState } from 'react';
import {
    EuiText,
    EuiPanel,
    EuiSelect,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFieldText,
    EuiFormRow,
    EuiTextAlign,
    EuiButton
  } from '@elastic/eui';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import '../style.css';

const UserManagement = () => {
  const [users, setUsers] = useState([]); // 用于存储用户列表
  const [selectedUser, setSelectedUser] = useState(null); // 用于存储选中的用户信息
  const [errorMessage, setErrorMessage] = useState(''); // 用于显示错误信息
  const [loading, setLoading] = useState(true); // 用于加载状态
  const [sortKey, setSortKey] = useState('id'); // 用于存储排序方式
  const [searchTerm, setSearchTerm] = useState(''); // 用于存储搜索关键词
  const [totalCount, setTotalCount] = useState(0);
  const [playlists, setPlaylists] = useState([]);
  const [selectedPlaylistSongs, setSelectedPlaylistSongs] = useState({});
  const [expandedPlaylistId, setExpandedPlaylistId] = useState(null);  // 存储当前展开的歌单ID

  // 加载用户列表
  useEffect(() => {
    const token = sessionStorage.getItem("token");  // 从 localStorage 中获取 token
    let userRole = "";
    if (token) {
        const decodedToken = jwtDecode(token);  // 解码 token
        // console.log(decodedToken);  // 检查 token 中的内容，包括角色信息
        userRole = decodedToken.role;  // 获取角色信息
    }

    // 只有管理员才能获取用户列表
    if (userRole !== 'Admin') {
      setErrorMessage('Access denied. Only admins can view this page.');
      setLoading(false);
      return;
    }

    // 发送请求获取用户列表
    axios.get('/api/users')
    .then(response => {
      setUsers(response.data); // 获取到所有用户数据并更新状态
      setTotalCount(response.data.length);  // 更新总条数
      setLoading(false); // 加载完成
    })
    .catch(error => {
      console.error('Error fetching users:', error);
      setErrorMessage('Failed to load users. Please try again later.');
      setLoading(false);
    });
  }, []);

  // 点击某个用户时显示其详细信息
  const handleUserClick = (user) => {
    setSelectedUser(user);
    setExpandedPlaylistId(null);  // 清空展开的歌单
    setSelectedPlaylistSongs({}); // 清空存储的诗歌列表
    setPlaylists([]);
  };

  // 根据搜索关键词过滤列表
  const filteredUsers = users.filter((user) =>
    user.username.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 对过滤后的用户列表进行排序
  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (sortKey === 'id') {
        return a.id - b.id;
    } else if (sortKey === 'username') {
        return a.username.localeCompare(b.username);
    }
    return 0;
  });

  if (loading) {
    return <p>Loading...</p>;  // 显示加载中的提示
  }

  if (errorMessage) {
    return <p>{errorMessage}</p>;  // 显示错误信息
  }

  const handleViewPlaylists = () => {
    axios.get(`/api/playlists/admin/user-playlists`, { params: { userId: selectedUser.id , type: 'song'} })
      .then(response => {
        setPlaylists(response.data);  // 获取用户所有歌单
      })
      .catch(error => {
        console.error('获取歌单失败:', error);
      });
  };
  
  const handleToggleSongs = (playlistId) => {
    if (playlistId === expandedPlaylistId) {
      setExpandedPlaylistId(null);  // 如果再次点击已展开的歌单，折叠
    } else {
      setExpandedPlaylistId(playlistId);  // 展开新的歌单
      // 如果诗歌列表还没有加载，则加载诗歌
      if (!selectedPlaylistSongs[playlistId]) {
        axios.get(`/api/playlists/${playlistId}/songs`)
          .then(response => {
            setSelectedPlaylistSongs(prevSongs => ({
              ...prevSongs,
              [playlistId]: response.data
            }));
          })
          .catch(error => {
            console.error('加载诗歌失败:', error);
          });
      }
    }
  };  

  return (
    <EuiFlexGroup>
    {/* 左侧用户列表 */}
    <EuiFlexItem grow={false} className="song-list-container">
      <EuiPanel paddingSize="s">
        <EuiFormRow label={`用户列表 共${totalCount}個`}>
          <EuiSelect
            options={[
              { value: 'username', text: '按名称排序' },
              { value: 'id', text: '按ID排序' }
            ]}
            value={sortKey}
            onChange={(e) => setSortKey(e.target.value)}
          />
        </EuiFormRow>

        {/* 搜索框 */}
        <input
            type="text"
            placeholder="搜索用戶..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />

        <ul className="song-list">
            {sortedUsers.map((user) => (
              <li key={user.id} onClick={() => handleUserClick(user)}>
                {user.username} (ID: {user.id})
              </li>
            ))}
        </ul>
      </EuiPanel>
    </EuiFlexItem>

    {/* 右侧详细信息编辑 */}
    <EuiFlexItem>
    {selectedUser ? (
        <EuiPanel paddingSize="l">
        <EuiTextAlign textAlign="center">
            <h3>查看用户: {selectedUser.username}</h3>
        </EuiTextAlign>

        <EuiFlexGroup>
            {/* 左列 */}
            <EuiFlexItem>
            <EuiFormRow label="User ID">
                <EuiFieldText value={selectedUser.id} readOnly />
            </EuiFormRow>

            <EuiFormRow label="User name">
                <EuiFieldText
                value={selectedUser.username} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Email">
                <EuiFieldText
                value={selectedUser.email} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Role">
                <EuiFieldText
                value={selectedUser.role} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Verification Code">
                <EuiFieldText
                    value={selectedUser.verificationCode || ''} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Code Generated At">
                <EuiFieldText
                    value={selectedUser.codeGeneratedAt || ''} readOnly
                />
            </EuiFormRow>
            </EuiFlexItem>

            {/* 右列 */}
            <EuiFlexItem>
            <EuiFormRow label="Reset Password Token">
                <EuiFieldText
                    value={selectedUser.resetPasswordToken || ''} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Reset Password Token Expiry">
                <EuiFieldText
                    value={selectedUser.resetPasswordTokenExpiry || ''} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Channel">
                <EuiFieldText
                    value={selectedUser.channel || ''} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Language">
                <EuiFieldText
                    value={selectedUser.language || ''} readOnly
                />
            </EuiFormRow>

            <EuiFormRow label="Reading Plan">
                <EuiFieldText
                    value={selectedUser.readingPlan || ''} readOnly
                />
            </EuiFormRow>    
            </EuiFlexItem>
        </EuiFlexGroup>
            <ul>
              {playlists.map(playlist => (
                <li key={playlist.id}>
                  {/* 歌单名称变为可点击 */}
                  <span onClick={() => handleToggleSongs(playlist.id)} style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}>
                    {playlist.name}
                  </span>

                  {/* 如果当前歌单是展开状态，则显示诗歌列表 */}
                  {expandedPlaylistId === playlist.id && (
                    <ul>
                      {selectedPlaylistSongs[playlist.id] ? (
                        selectedPlaylistSongs[playlist.id].map(song => (
                          <li key={song.resourceid}>{song.name}  ({song.engineID})</li>
                        ))
                      ) : (
                        <li>加载中...</li>  // 还没有加载诗歌时显示的内容
                      )}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
            <div style={{ textAlign: 'center' }}>
              <EuiButton onClick={handleViewPlaylists}>查询用户歌单</EuiButton>
            </div>
        </EuiPanel>
    ) : (
        <EuiText>请选择一个用户以查看详细信息。</EuiText>
    )}
    </EuiFlexItem>
    </EuiFlexGroup>
)};

export default UserManagement;