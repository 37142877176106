import React, { useState, useEffect, memo } from 'react';
import { EuiModal, EuiModalHeader, EuiModalBody, EuiModalFooter, EuiButton, EuiSelect } from '@elastic/eui';
import {TranslateLanguage} from './KeywordTranslator';
import axios from 'axios';
import { useAlert } from './AlertProvider';
import MultiVerseModal from "../components/MultiVerseModal"

const FavoriteModal = memo(({ userId, songId, videoURL, type, engineId, title, label, playlisttype, resource, closeModal }) => {
    const [selectedPlaylistId, setSelectedPlaylistId] = useState('');
    const [playlists, setPlaylists] = useState([]);
    const [showMultiVerseModal, setShowMultiVerseModal] = useState(false);
    const showAlert = useAlert();
    const initialVerse = type === "經文" ? parseInt(resource.verse_number.raw) : "";
    const [startVerse, setStartVerse] = useState(initialVerse);
    const [endVerse, setEndVerse] = useState(initialVerse);

    const extractSongIdFromUrl = (url) => {
        const pattern = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const match = url.match(pattern);
        return match ? match[1] : null;
    };

    // const songId_new = extractSongIdFromUrl(videoURL);
    // console.log("Extracted songId:", songId_new);

    useEffect(() => {
        axios.get(`/api/playlists`, {
            params: {
                type: playlisttype,
                userId: userId
            }
        })
        .then(response => {
            const data = response.data;
            // 如果 data 不是数组，则将其设置为空数组
            if (!Array.isArray(data)) {
                console.error('Fetched playlists data is not an array:', data);
                setPlaylists([]);
            } else {
                setPlaylists(data);
                // 默认选择第一个歌单
                if (data.length > 0) {
                    setSelectedPlaylistId(data[0].id);
                }
            }
        })
        .catch(error => {
            console.error('Error fetching playlists:', error);
        });
    }, [userId]);

    const handleMultipleCollection = () => {
        setShowMultiVerseModal(true);
     };

    const handleAddResourceToFavoritelist = () => {
        if (label === "v"){
            var songId_new = extractSongIdFromUrl(videoURL);
            if (songId_new != null){
                songId = songId_new;
            }
            else{
                label = "t";
            }
        }
            
        if (selectedPlaylistId) {
            // 获取当前歌单中的歌曲列表
            axios.get(`/api/playlists/${selectedPlaylistId}/resources`)
            .then(response => {
                let songs = response.data || []; // 如果 songs 是空数组或 undefined，设置为一个空数组
                
                // 检查当前歌单中是否已经有相同的 songId
                const songExists = songs.some(song => song.resourceId === songId);

                if (!songExists) {
                    // 如果歌单中没有该 songId，则添加歌曲
                    const requestBody = {
                        ResourceId: songId,
                        Name: title,
                        Link: videoURL,
                        type: type,
                        Description: type === "經文" ? startVerse+","+endVerse:"",
                        label: label,
                        engineId: engineId
                    };

                    axios.post(`/api/playlists/${selectedPlaylistId}/addsongs`, requestBody, {
                        headers: {
                            'Content-Type': 'application/json',
                        }
                    })
                    .then(response => {
                        if (response.status === 200) {
                            // console.log("Song added to playlist successfully");
                            showAlert(TranslateLanguage(localStorage.userLanguage, "保存成功"));
                        } else {
                            // console.error("Failed to add song to playlist");
                            showAlert(TranslateLanguage(localStorage.userLanguage, "保存失敗"));
                        }
                        closeModal(); // 关闭模态框
                    })
                    .catch(error => {
                        console.error("Error adding song to playlist:", error);
                    });
                } else {
                    console.log("Song already exists in the playlist");
                    showAlert(TranslateLanguage(localStorage.userLanguage, "資源已在收藏單中"));
                    closeModal(); // 关闭模态框
                }
            })
            .catch(error => {
                console.error("Error fetching playlist songs:", error);
            });
        }
    };

    return (
        <div>
        <EuiModal onClose={closeModal}>
            <EuiModalHeader>{TranslateLanguage(localStorage.userLanguage, "選擇收藏單")}</EuiModalHeader>
            <EuiModalBody>
                <EuiSelect
                    options={playlists.map(playlist => ({
                        value: playlist.id,
                        text: playlist.name
                    }))}
                    value={selectedPlaylistId}
                    onChange={e => setSelectedPlaylistId(e.target.value)}
                />
            </EuiModalBody>
            <EuiModalFooter>
                {type === "經文" && (
                    <EuiButton onClick={handleMultipleCollection} fill>{TranslateLanguage(localStorage.userLanguage, "多節收藏")}</EuiButton>
                )}              
                <EuiButton onClick={handleAddResourceToFavoritelist} fill>{TranslateLanguage(localStorage.userLanguage, "保存")}</EuiButton>
            </EuiModalFooter>
        </EuiModal>
        {showMultiVerseModal && (
            <MultiVerseModal
                book={resource.chapter_code_name.raw} 
                bookname={resource.chapter_name.raw}
                chapter={resource.chapter_number.raw} 
                language={localStorage.userLanguage} 
                selectedVerse={resource.verse_number.raw}
                savetolist={handleAddResourceToFavoritelist}
                setStartVerse={setStartVerse}
                setEndVerse={setEndVerse}
                closeModal={closeModal} 
            />
        )}
        </div>
    );
});

export default FavoriteModal;
