import React, { useState, useEffect } from 'react';
import {useNavigate} from "react-router-dom";
import {TranslateLanguage} from '../components/KeywordTranslator';
import {
  EuiForm,
  EuiFormRow,
  EuiSelect,
  EuiButtonEmpty,
  EuiFieldText,
  EuiFieldPassword,
  EuiSpacer,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiCallOut,
  EuiTitle
} from '@elastic/eui';

const SettingsPage = () => {
    const navigate = useNavigate();
    const [language, setLanguage] = useState('T');
    const [channel, setChannel] = useState('CGNTV');
    const [plan, setPlan] = useState('HT');
    const [newUsername, setNewUsername] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showUsernameField, setShowUsernameField] = useState(false);
    const [showPasswordFields, setShowPasswordFields] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const channelOptions = [
        { value: 'CGNTV', text:  TranslateLanguage(language, "CGNTV 中文台 活潑的生命") },
        { value: 'CAI', text: TranslateLanguage(language, "CGNTV 中文台 二重靈糧堂") },
        { value: 'GCH', text: TranslateLanguage(language, "台北榮耀堂 （週一至週五）") },
    ];
    const languageOptions = [
        { value: 'T', text: '繁體' },
        { value: 'S', text: '简体' },
    ];
    const planOptions = [
        { value: 'HT', text: TranslateLanguage(language, "時序") },
        { value: 'OT', text: TranslateLanguage(language, "舊約開始") },
        { value: 'NT', text: TranslateLanguage(language, "新約開始") }
    ];

    const user = JSON.parse(sessionStorage.getItem('user'));

    useEffect(() => {
        if (user)
        {
            if (user.language)
                setLanguage(user.language);
            if (user.channel)
                setChannel(user.channel);
            if (user.readingPlan)
                setPlan(user.readingPlan);
            if (user.username)
                setNewUsername(user.username);
        }
      }, []);

    // Handle functions
    const handleLanguageChange = (e) => setLanguage(e.target.value);
    const handleChannelChange = (e) => setChannel(e.target.value);
    const handlePlanChange = (e) => setPlan(e.target.value);
    const handleUsernameChange = (e) => setNewUsername(e.target.value);
    const handleCurrentPasswordChange = (e) => setCurrentPassword(e.target.value);
    const handlePasswordChange = (e) => setNewPassword(e.target.value);
    const handleConfirmPasswordChange = (e) => setConfirmPassword(e.target.value);

    const showError = (message) => {
        setError(message);
    
        // 使用 setTimeout 在指定时间后清除错误
        setTimeout(() => {
            setError('');
        }, 3000);  // 3000 毫秒（3秒）后清除错误
    };   

    const validatePassword = () => {
        if (newPassword.length < 6) {
            showError(TranslateLanguage(language, "密碼至少6位"));
            return false;
        }
        if (!/[A-Za-z]/.test(newPassword) || !/[0-9]/.test(newPassword)) {
            showError(TranslateLanguage(language, "密碼需要包含字符和數字"));
            return false;
        }
        if (newPassword !== confirmPassword) {
            showError(TranslateLanguage(language, "確認密碼不匹配"));
            return false;
        }
        return true;
      };

    const handleSaveSettings = async () => {
        const settings = { language: language, channel: channel, readingPlan: plan, username: newUsername, userId: user.id };
        if (newUsername === "") {
            showError(TranslateLanguage(language, "用戶名不能為空"));
            return false;
          }

        try {
        const response = await fetch('/api/users/settings', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(settings),
        });
        
        if (!response.ok) {
            throw new Error('Failed to save settings');
        }
        setSuccess(TranslateLanguage(language, "設置成功"));
        user.language = language;
        user.channel = channel;
        user.readingPlan = plan;
        user.username = newUsername;
        sessionStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.userLanguage = language;
        localStorage.qtOption = channel;
        localStorage.yearReadOption = plan;
        navigate(-1);
        } catch (error) {
            console.error('Error saving settings:', error);
            setError(TranslateLanguage(language, "設置失敗"));
        }
    };
    
    const handleSavePassword = async () => {
        const userId = user.id;
        if (validatePassword()) {
            try {
                const response = await fetch(`/api/users/${userId}/change-password`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ currentPassword, newPassword }),
                });
                const data = await response.json(); // 解析 JSON 响应
                
                if (!response.ok) {
                    // 使用服务器返回的错误信息
                    throw new Error(data.message || 'Failed to change password');
                }
                setSuccess(TranslateLanguage(language, "密碼修改成功"));
                setError('');
                setShowPasswordFields(false);
            } catch (error) {
                console.error('Error changing password:', error.message);
                setError(error.message); // 显示错误消息
            }
        }
    };    

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <EuiSpacer size="m" />
            <EuiFlexGroup direction="column" alignItems="center" justifyContent="center" style={{ maxWidth: '700px', width: '100%' }}>
                <EuiTitle size="l">
                    <h1>{TranslateLanguage(language, "用戶設置")}</h1>
                </EuiTitle>
                <EuiFlexItem>
                <EuiForm>
                {error && <EuiCallOut title="Error" color="danger">{error}</EuiCallOut>}
                {success && <EuiCallOut title="Success" color="success">{success}</EuiCallOut>}

                <EuiFormRow label={TranslateLanguage(language, "語言選項")}>
                    <EuiSelect options={languageOptions} value={language} onChange={handleLanguageChange} />
                </EuiFormRow>
                <EuiFormRow label={TranslateLanguage(language, "活潑的生命頻道")}>
                    <EuiSelect options={channelOptions} value={channel} onChange={handleChannelChange} />
                </EuiFormRow>
                <EuiFormRow label={TranslateLanguage(language, "全年讀經計劃")}>
                    <EuiSelect options={planOptions} value={plan} onChange={handlePlanChange} />
                </EuiFormRow>

                <EuiSpacer size="l" />

                <EuiButtonEmpty iconType="pencil" onClick={() => setShowUsernameField(!showUsernameField)}>
                    {showUsernameField ? TranslateLanguage(language, "取消修改用戶名") : TranslateLanguage(language, "修改用戶名")}
                </EuiButtonEmpty>
                {showUsernameField && (
                    <>
                    <EuiFormRow label={TranslateLanguage(language, "新用戶名")}>
                        <EuiFieldText value={newUsername} onChange={handleUsernameChange} />
                    </EuiFormRow>
                    </>
                )}

                <EuiSpacer size="l" />

                <EuiButtonEmpty iconType="lock" onClick={() => setShowPasswordFields(!showPasswordFields)}>
                    {showPasswordFields ? TranslateLanguage(language, "取消修改密碼") : TranslateLanguage(language, "修改密碼")}
                </EuiButtonEmpty>
                {showPasswordFields && (
                    <>
                    <EuiFormRow label={TranslateLanguage(language, "當前密碼")}>
                        <EuiFieldPassword type="dual" value={currentPassword} onChange={handleCurrentPasswordChange} />
                    </EuiFormRow>
                    <EuiFormRow label={TranslateLanguage(language, "新密碼")}>
                        <EuiFieldPassword type="dual" value={newPassword} onChange={handlePasswordChange} />
                    </EuiFormRow>
                    <EuiFormRow label={TranslateLanguage(language, "確認新密碼")}>
                        <EuiFieldPassword type="dual" value={confirmPassword} onChange={handleConfirmPasswordChange} />
                    </EuiFormRow>
                    <EuiButton onClick={handleSavePassword} fill>{TranslateLanguage(language, "保存密碼")}</EuiButton>
                    </>
                )}
                <EuiSpacer size="l" />

                <EuiFlexItem grow={false}>
                    <EuiButton onClick={handleSaveSettings} fill>{TranslateLanguage(language, "保存設定")}</EuiButton>
                    <EuiSpacer size="m" />  {/* 在兩個按鈕之間插入間距 */}
                    <EuiButton onClick={() => {navigate(-1)}} fill>{TranslateLanguage(language, "返回主頁")}</EuiButton>
                </EuiFlexItem>
            </EuiForm>
        </EuiFlexItem>
        </EuiFlexGroup>
    </div>
  );
};

export default SettingsPage;
