import React ,{useState, useEffect} from 'react';
import YouTube from "react-youtube";
import { 
        EuiButtonEmpty,
        EuiFlyout,
        EuiFlyoutBody,
        EuiFlyoutHeader,
        EuiFlexGroup,
        EuiFlexItem,
        EuiSpacer,
        EuiButton,
        EuiSwitch,
        EuiTitle,
        EuiFlyoutFooter,
        EuiDragDropContext,
        EuiDraggable,
        EuiDroppable,
        EuiPanel,
        EuiIcon,
        EuiText,
        EuiModal,
        EuiModalHeader,
        EuiModalBody,
        EuiModalHeaderTitle,
        EuiModalFooter,
        euiDragDropReorder,
        copyToClipboard } from '@elastic/eui';
import {TranslateLanguage} from '../components/KeywordTranslator';                
import '../style.css';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import LoadUserSettings, {RemovePlayListItem, SaveUserSettings, ExtractItemFromURL} from '../components/UserServices';
import Footer from '../components/Footer';

const apiURL = process.env.REACT_APP_MasterApiURL;
const apiKey = process.env.REACT_APP_APIKey;

var videoJson = '[{"id": "i1", "playListId": "1", "title": "耶穌，我愛你", "videoId": "loJ8jTRfCik"}, {"id": "i2", "playListId": "1", "title": "活出愛", "videoId": "lfP-3JLVvaw"}, {"id": "i3", "playListId": "1", "title": "我們高舉雙手" , "videoId": "7gB0drDYJy4"}]'
var videoIdList =  (LoadUserSettings("","worshipSongList", true)) ? LoadUserSettings("","worshipSongList", true) : JSON.parse(videoJson);
// var videoIdList =  JSON.parse(videoJson);

function PlaylistPlayer(props) {

  const [videoId, setVideoId] = useState("loJ8jTRfCik");
  const [currentVideoId, setCurrentVideoId] = useState(0);
  const [closePanelWhenPlay, setClosePanelWhenPlay] = useState(false);
  const [loopPlay, setLoopPlay] = useState(false);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(true);
  const [isModalVisible, setisModalVisible] = useState(false);
  const [list, setList] = useState(videoIdList);
  const [shareLink, setSharelink] = useState("");
  const currentURL = window.location.origin;
  const language = localStorage.userLanguage;
  const divStyle = { "text-align": "center" };
  const urlParams = new URLSearchParams(window.location.search);
  
  const getList = async () =>{
    if(urlParams.has('items')){

      var itemList = urlParams.get('items');
      var matchItemList = atob(itemList).split(',');
      var filterList =[];
          matchItemList.forEach((element, index) => {
          filterList.push("ctb-worship-tc|" + element)
        });
      await fetch(apiURL,{
              method:"POST",
              headers:{
                  "Content-Type":"application/json, charset=UTF-8",
                  "Authorization":  "Bearer search-" + apiKey
              },
              body:JSON.stringify({
                  query:"",
                  filters: {"any": {"id": filterList}  }, 
                  page:{size: 1000},
                  sort:{id: "asc"}
              })
          })
          .then(function(response){
              return(response.json())
          })
          .then(function(data){
              var results = JSON.parse(JSON.stringify(data));
              var extractedItems = [];
              filterList.forEach((element, index) => {
                var foundItem = results.results.find(({id}) => id.raw === element)
                if(foundItem){
                  var videoId = foundItem.link.raw.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/)[1];
                  extractedItems.push({"id": foundItem.id.raw, "playListId": "1", "title": foundItem.title.raw , "videoId": videoId});  
                }
              })
              setList(extractedItems);
              setIsFlyoutVisible(false);
              loadVideo(currentVideoId);
          }
        )
    }
  }

  const opts = {
    width:"100%",
    height: "90%",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
       autoplay: 1,
       playsinline: 1,
       enablejsapi: 1,
       fullscreen: 1
     //  mute: 1
    }
  };     
    useEffect(()=>{
        // list.length > 0 && loadVideo(currentVideoId);
       urlParams.has('items') ? getList() : loadVideo(currentVideoId);

     },[])

    const loadVideo = (videoIndex) => {
        if(videoIndex < list.length && videoIndex >= 0){
            setVideoId(list[videoIndex].videoId);
            setCurrentVideoId(videoIndex)
         }
    }

    const onReady = (e) => {
      e.target.playVideo();
    }
    const onClosePanelWhenPlay = (e) => {
      setClosePanelWhenPlay(e.target.checked);
      setIsFlyoutVisible(false);
    }

    const onLoopPlay = (e) => {
      setLoopPlay(e.target.checked);
    }
 
    const checkStateChange = (e) => {
        if(e.target.playerInfo.playerState === 0){
            // auto next
            var nextId = currentVideoId + 1;
            if(loopPlay && nextId >= videoIdList.length ){
               nextId = 0;
            }
            loadVideo(nextId);
            setIsFlyoutVisible(false);        
          }
        if(e.target.playerInfo.playerState !== 0 ){
          setIsFlyoutVisible(true);
        }
        if(e.target.playerInfo.playerState === 1 && closePanelWhenPlay){
          setIsFlyoutVisible(false);
        }
      };

      const onDragEnd = ({ source, destination }) => {
        if (source && destination) {
          const items = euiDragDropReorder(list, source.index, destination.index);
          setList(items);
          // localStorage.worshipSongList = JSON.stringify(items);
          SaveUserSettings("","worshipSongList",items, true);
          window.location.reload();
        }
      };

      const onRemoveItem = (index) => {
        var newList = RemovePlayListItem("", 1, index);
        setList(newList);
        window.location.reload();
      }

      const shareClicked = ()=> {
        var items = "";
        list.forEach((element, index) => {
          items += element.id.replace("ctb-worship-tc|","") +",";
        })
        items = window.location.origin + "/sharesong?items=" + btoa(items.substring(0,items.length-1));
        setSharelink(items);
        setisModalVisible(true);
      }

      const closeModal =()=> {
        copyToClipboard(shareLink);
        setisModalVisible(false);
      }

      let modal;
      if (isModalVisible) {
        modal = (
          <EuiModal onClose={closeModal}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                <h2> {TranslateLanguage(language, "分享我的歌單")}</h2>
              </EuiModalHeaderTitle>
            </EuiModalHeader>
    
            <EuiModalBody>
            {TranslateLanguage(language, "您可以使用以下的連結來分享您的歌單。")}
              <EuiSpacer />{shareLink}
            </EuiModalBody>
    
            <EuiModalFooter>
              <EuiButton onClick={closeModal} fill>
              {TranslateLanguage(language, "複製 Copy Link")}
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        );
      }

    let flyout;
    if (isFlyoutVisible) {
      flyout = (
        <EuiFlyout
          type="push"
          size="300px"
          onClose={() => setIsFlyoutVisible(false)}
          aria-labelledby="myworshipsongs"
        >
          <EuiFlyoutHeader hasBorder>
          <div  style={divStyle}>
            <span><a href={currentURL}><img  src={logosmall} alt="Check The Bible" /></a> </span>
            {/* <span align="center"><a href={currentURL}><picture>
                                  <source media="(min-width:1280px)" srcSet={logomedium} />
                                  <source media="(min-width:768px)" srcSet={logomedium} />
                                  <img src={logosmall}  alt="Check The Bible" />
            </picture></a></span> */}
                <EuiTitle size="m"><h2 id="myworshipsongs">{TranslateLanguage(language, "播放我的歌單")}</h2></EuiTitle>

          </div>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>
              <EuiDragDropContext onDragEnd={onDragEnd} >
                <EuiDroppable droppableId="DROPPABLE_AREA" spacing="none">
                  {list.map(({ videoId, title, id }, idx) => (
                    <EuiDraggable spacing="none" key={id} index={idx} draggableId={id} paddingSize="none" hasBorder={true}>
                      {(provided, state) => (
                        <EuiPanel hasShadow={state.isDragging}  paddingSize="none" color={idx === currentVideoId ? "danger" : "none"} hasBorder={true}>
                          <EuiIcon type="grab" style={{"margin": "8px"}}/>
                          <EuiButtonEmpty iconType="cross" onClick={()=> onRemoveItem(idx)} ></EuiButtonEmpty>
                          <EuiButtonEmpty
                            display="base"
                            size="s"
                            aria-label="Next"
                            paddingSize="none"
                            onClick = {() =>  loadVideo(idx)}
                          >{TranslateLanguage(language, 
                                              title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ") != "" ? 
                                              title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ").substring(0,13) : title)}
                            {title.replace(/[!^0-9a-z-A-Z()（）/? ]/g, "").replace(/ +/, " ").length > 14 ? "..." : ""}
                          </EuiButtonEmpty>
                       
                        </EuiPanel>
                      )}
                    </EuiDraggable>
                  ))}
                </EuiDroppable>
              </EuiDragDropContext>
              <EuiSpacer></EuiSpacer>
              <EuiSwitch
                  label="播放時關閉歌單"
                  checked={closePanelWhenPlay}
                  paddingSize="none"
                  onChange={(e) => onClosePanelWhenPlay(e)}
              />
              <EuiSpacer></EuiSpacer>
              <EuiSwitch
                  label="連續播放"
                  checked={loopPlay}
                  paddingSize="none"
                  onChange={(e) => onLoopPlay(e)}
              />
              <EuiSpacer size='s'></EuiSpacer>
              <EuiText size="s">目前自動播放功能只適用於PC, MacOS, 以及安卓手機</EuiText>
              
          </EuiFlyoutBody>
          <EuiFlyoutFooter>
            {/* <EuiButton size="s" onClick={() => setIsFlyoutVisible(false)}>隱藏</EuiButton> */}
            <EuiButton size="s" color="primary" fill onClick={() => shareClicked()}>分享</EuiButton>
          </EuiFlyoutFooter>
        </EuiFlyout>
       );
    }      

    return (
      <div>
      <div>
        {flyout}
        {modal}
      </div>
      <div>
        <div style={{ 
          width: isFlyoutVisible ? 'calc(100% - 300px)' : '100%', // 动态设置宽度
          position: 'relative', 
          paddingTop: '56.25%', /* 16:9 比例 */ 
          height: '95vh',  // 设置为视口高度以确保填满页面
          }}> 
          <YouTube
            id="ytPlayer"
            videoId={videoId}
            className="yt-iframe"
            containerClassName="yt-container"
            onStateChange={(e) => checkStateChange(e)}
            onReady={onReady}
            opts={opts}
            style={{
              position: 'absolute',
              top: 0,
              left: '50%',
              transform: 'translateX(-50%)', // 水平居中
              width: '100%', // 确保宽度占满容器
              height: '90%', // 确保高度占满容器
              objectFit: 'cover',  // 让视频充满容器，并裁剪溢出的部分
            }}
          />
        </div>
        <div>

        </div>
      </div>

    </div>
  )
}

export default PlaylistPlayer