import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EuiSpacer, EuiButtonEmpty, EuiText, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { TranslateLanguage } from '../components/KeywordTranslator';
import { useModal } from '../ModalContext';
import { jwtDecode } from "jwt-decode"; 

export default function Footer(props) {
    const withLanguage = props.withLanguage;
    const navigate = useNavigate();
    const { setLoginModalVisible, setRegisterModalVisible } = useModal();
    const language = localStorage.userLanguage;
    var currentBrowserLanguage = (navigator.language || navigator.userLanguage) === "zh-CN" ? "S" : "T";
    var userLanguage = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
    localStorage.userLanguage = userLanguage;
    const [languageState, setLanguageState] = useState(userLanguage);
    //const [username, setUsername] = useState(localStorage.getItem('username') ? localStorage.getItem('username').split('@')[0] : null);
    const user = JSON.parse(sessionStorage.getItem('user')); // 获取用户信息
    //const [username, setUsername] = useState(sessionStorage.getItem('username') ? sessionStorage.getItem('username').split('@')[0] : null);
    const [username, setUsername] = useState(sessionStorage.getItem('user') ? user.username : null)
    const token = sessionStorage.getItem("token");  // 从 localStorage 中获取 token
    let userRole = "";
    if (token) {
        const decodedToken = jwtDecode(token);  // 解码 token
        // console.log(decodedToken);  // 检查 token 中的内容，包括角色信息
        userRole = decodedToken.role;  // 获取角色信息
    }

    const onChangeLanguage = () => {
        language === "T" ? setLanguageState("S") : setLanguageState("T");
        language === "T" ? localStorage.userLanguage = "S" : localStorage.userLanguage = "T";
        window.scrollTo(0, 0);
        window.location.reload();
    };

    const handleSettingClick = () => {
        // 检查 sessionStorage 中是否存在用户信息
        if (username) {
            // 如果用户已登录，跳转到 settingspage
            navigate('/settingspage');
        } else {
            // 如果用户未登录，跳转到 settings 页面
            navigate('/settings');
        }
    };

    const onLogout = () => {
        localStorage.removeItem('username');
        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        localStorage.removeItem('token');
        sessionStorage.removeItem('token')
        setUsername(null);
        navigate('/');
    };

    const onManagement = () => {       
        navigate('/adminpage');
    };

    return (
        <div className="footer">
            <EuiSpacer></EuiSpacer>
            <EuiFlexGroup justifyContent="center" gutterSize="s" alignItems="center" style={{ flexWrap: 'wrap' }}>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { navigate("/") }}>{TranslateLanguage(language, "首頁")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '80px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/about" target="_blank">{TranslateLanguage(language, "有關我們")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/vision" target="_blank">{TranslateLanguage(language, "異象")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/mission" target="_blank">{TranslateLanguage(language, "事工")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '100px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" href="https://www.checkthebible.org/contact" target="_blank">{TranslateLanguage(language, "與我們聯繫")}</EuiButtonEmpty></EuiFlexItem>
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '80px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { navigate("/howtouse") }}>{TranslateLanguage(language, "如何使用")}</EuiButtonEmpty></EuiFlexItem>
                {withLanguage === "1" && (
                    <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { onChangeLanguage() }}>
                        {language === "T" ? "简体" : "繁體"}
                    </EuiButtonEmpty></EuiFlexItem>
                )}
                <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '80px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { handleSettingClick() }}>
                    {TranslateLanguage(language, "我的設定")}
                </EuiButtonEmpty></EuiFlexItem>
                {username ? (
                    <>
                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '100px', minWidth: '30px', maxWidth: '100px' }}><EuiText size="xs">{TranslateLanguage(language, "歡迎，")}{username}</EuiText></EuiFlexItem>
                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={onLogout}>{TranslateLanguage(language, "登出")}</EuiButtonEmpty></EuiFlexItem>
                        {userRole == "Admin" &&(
                            <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={onManagement}>{TranslateLanguage(language, "管理")}</EuiButtonEmpty></EuiFlexItem>
                        )}
                    </>
                ) : (
                    <>
                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { setLoginModalVisible(true) }}>{TranslateLanguage(language, "登入")}</EuiButtonEmpty></EuiFlexItem>
                        <EuiFlexItem grow={false} style={{ flexShrink: 0, flexBasis: '50px', minWidth: '30px', maxWidth: '100px' }}><EuiButtonEmpty size="xs" color="primary" onClick={() => { setRegisterModalVisible(true) }}>{TranslateLanguage(language, "註冊")}</EuiButtonEmpty></EuiFlexItem>
                    </>
                )}
            </EuiFlexGroup>
            <EuiSpacer></EuiSpacer>
        </div>
    );
}

