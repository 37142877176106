import React, { useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { EuiPanel, EuiFlexGroup, EuiFlexItem, EuiButtonIcon, EuiListGroup, EuiListGroupItem, EuiText, EuiButton, EuiIcon} from '@elastic/eui';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import '../style.css';
import { TranslateLanguage } from './KeywordTranslator';
import axios from 'axios';

const apiURL = process.env.REACT_APP_MasterApiURL;
const apiKey = process.env.REACT_APP_APIKey;

const SharedFavoritePage = () => {
    const { playlistId } = useParams();
    const [playlist, setPlaylist] = useState(null);
    const [currentSong, setCurrentSong] = useState({ id: '', name: '' });
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPanelVisible, setIsPanelVisible] = useState(true);
    const [currentResource, setCurrentResource] = useState('');
    const navigate = useNavigate();
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const [verses, setVerses] = useState(''); // 使用 useState 定义 verses

    // function fetchSharedPlaylist() {
    //     const shareCode = window.location.pathname.split("/").pop(); // 从 URL 中提取分享代码
    //     axios.get(`/api/playlists/sharedplaylist/${shareCode}`, {
    //         headers: {}  // 覆盖全局拦截器中的 Authorization 头
    //       })
    //         .then(response => {
    //             const data = response.data;
    //             setPlaylist(data);
    //         })
    //         .catch(error => console.error('加载分享的歌单时出错:', error));
    // }

    useEffect(() => {
        // 确保钩子被无条件调用
        if (playlist) {
            setCurrentSong(playlist.playlistResources[currentSongIndex]);
        } else {
            setCurrentSong({ id: '', name: '' }); // 默认值，避免 null 问题
        }
    }, [playlist, currentSongIndex]);
    
    // 组件挂载或 playlistId 改变时，获取播放列表数据
    useEffect(() => {
        const fetchSharedPlaylist = async () => {
            try {
                const shareCode = window.location.pathname.split("/").pop();
                const response = await axios.get(`/api/playlists/sharedplaylist/${shareCode}`);
                setPlaylist(response.data);
            } catch (error) {
                console.error('加载分享的歌单时出错:', error);
            }
        };
    
        fetchSharedPlaylist();
    }, [playlistId]);
    

    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
    if (!currentSong.engineID) return;
            const [enginePrefix, engineNumberStr] = currentSong.engineID.split("|");
            const engineNumber = parseInt(engineNumberStr, 10);
            setCurrentResource(null);
            fetch(apiURL, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json, charset=UTF-8",
                    "Authorization": "Bearer search-" + apiKey
                },
                body: JSON.stringify({
                    query: "",
                    filters: { "any": { "id": currentSong.engineID } },
                    page: { size: 1000 },
                    sort: { id: "asc" }
                })
            })
            .then(response => response.json())
            .then(data => {
                const results = data.results;
                if (results.length > 0) {
                    const result = results[0];
                    if (result.doctype.raw === "禱告") {
                        setCurrentResource(result.prayer.raw);
                    } else if (result.doctype.raw === "經文") {
                        const verseNumber = parseInt(result.verse_number.raw, 10);
                        let startVerse, endVerse;
                        // 解析 description 字段，获取起始和结束节
                        const descriptionRange = currentSong.description.split(",");
                        if (
                            descriptionRange.length === 2 &&
                            descriptionRange[0] !== descriptionRange[1]
                        ) {
                            startVerse = parseInt(descriptionRange[0], 10);
                            endVerse = parseInt(descriptionRange[1], 10);
                        } else {
                            startVerse = verseNumber;
                            endVerse = verseNumber;
                        }
                        // 计算新的 engineID 范围
                        const startEngineID = `${enginePrefix}|${engineNumber + startVerse - verseNumber}`;
                        const endEngineID = `${enginePrefix}|${engineNumber + endVerse - verseNumber}`;
                        if (startEngineID !== endEngineID)
                        {
                            // 生成需要的 ID 列表
                            const idList = [];
                            for (let i = startVerse; i <= endVerse; i++) {
                                idList.push(`${enginePrefix}|${engineNumber + i - verseNumber}`);
                            }
                            // 发起新的查询以获取多节经文
                            fetch(apiURL, {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json, charset=UTF-8",
                                    "Authorization": "Bearer search-" + apiKey
                                },
                                body: JSON.stringify({
                                    query: "",
                                    filters: {
                                        "any": {
                                            "id": idList // 使用批量ID查询
                                        }
                                    },
                                    page: { size: 1000 },
                                    sort: { id: "asc" }
                                })
                            })
                            .then(response => response.json())
                            .then(data => {
                                const newVerses  = data.results
                                    .map(v => `${v.verse_number.raw} ${v.title.raw}`) // 在每段前面添加節數
                                    .join(" ");
                                setVerses(newVerses); 
                                setCurrentResource(`${result.chapter_name.raw} ${result.chapter_number.raw}: ${startVerse}-${endVerse}`);
                            })
                            .catch(() => {
                                setCurrentResource(null);
                            });
                        }
                        else{
                            setVerses(currentSong.name);
                            setCurrentResource(`${result.chapter_name.raw} ${result.chapter_verse_number.raw}`);
                        }                            
                    } else if (["拾穗", "註解", "例證", "綱目"].includes(result.doctype.raw)) {
                        let resultText = formatText(result.title.raw);
                        const resultLink = result.link.raw;
                        loadContentLocal(resultLink, resultText);
                    } else if (result.doctype.raw === "網路") {
                        setCurrentResource(result.description.raw);
                    } else {
                        setCurrentResource(null);
                    }
                } else {
                    setCurrentResource(null);
                }
            })
            .catch(() => {
                setCurrentResource(null);
            });
    }, [currentSong, apiURL, apiKey]);

    const panelStyle = {
        position: 'sticky',
        top: 0,
        zIndex: 100,
        height: isPanelVisible 
        ? (isPortrait ? '45vh' : '80vh') // When panel is visible
        : (isPortrait ? '80vh' : '80vh') // When panel is not visible
      };

    const opts = {
        height: '100%',
        width: '100%',
        playerVars: {
            autoplay: 1,
            playsinline: 1,
            enablejsapi: 1,
            fullscreen: 1
        },
    };

    const togglePanel = () => {
        setIsPanelVisible(!isPanelVisible);
    };

    const handleSongEnd = () => {
        if (playlist && playlist.playlistResources) {
            const nextIndex = (currentSongIndex + 1) % playlist.playlistResources.length;
            setCurrentSongIndex(nextIndex);
        }
    };

    if (!playlist) {
        return <div>加载中...</div>;
    }
    
    const getTextAlignment = (resource) => {
        if (resource && resource.resourceType === '經文') { 
            return 'right'; // 如果是經文，文本靠右
        }
        return 'left'; // 其他情况文本靠左
    };

    function extractContent(stringHTML) {
        var content =""
        var span = document.createElement('span');
        span.innerHTML = stringHTML;
        var contextExtracted = span.getElementsByTagName("table")[0];
        if(contextExtracted){
          contextExtracted.getElementsByTagName("u")[0].remove();
          contextExtracted.querySelectorAll("span, p, strong")
          .forEach(el => el.textContent.trim() === "" && el.parentNode.removeChild(el));
          content = contextExtracted.innerHTML;  
        }
        
        return content;
      };
    
    const textTitleStyle = {
        fontSize: '20px',
        lineHeight: '1.6',
        color: '#333',
        padding: '15px',
        whiteSpace: 'pre-wrap',
        overflowY: 'auto',
        textAlign: 'center',
        fontWeight: '600',
    };

    const textContainerStyle = {
        height: isPanelVisible
            ? (isPortrait ? 'calc(50vh - 52px)' : 'calc(90vh - 52px)')
            : (isPortrait ? 'calc(90vh - 52px)' : 'calc(90vh - 52px)'),
        overflow: 'auto',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    };
    const textContainerStyleWithTitle = {
        height: isPanelVisible
            ? (isPortrait ? 'calc(50vh - 100px)' : 'calc(90vh - 100px)')
            : (isPortrait ? 'calc(90vh - 100px)' : 'calc(90vh - 100px)'),
        overflow: 'auto',
        padding: '10px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        backgroundColor: '#f9f9f9',
    };
    const textVerseContainerStyle = {
        height: isPanelVisible
            ? (isPortrait ? 'calc(50vh - 52px)' : 'calc(90vh - 52px)')
            : (isPortrait ? 'calc(90vh - 52px)' : 'calc(90vh - 52px)'),
        border: '1px solid #ccc',
        borderRadius: '5px',
        fontSize: '20px', // 字体大小
        lineHeight: '1.8', // 行高
        padding: '20px', // 内边距
        margin: '10px', // 外边距，避免容器紧贴其他元素
        color: '#333', // 字体颜色，深灰色
        backgroundColor: '#f7f7f7', // 背景颜色，淡灰色
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', // 阴影效果，使容器更加立体
        overflowY: 'auto', // 支持滚动
        maxHeight: '70vh', // 限制容器高度，避免页面过长
        whiteSpace: 'pre-wrap', // 保持空白和换行
        fontWeight: '520', // 设置文字粗细为中等粗
    };

    const loadContentLocal = async (filePath, resultText) => {
        filePath = filePath.replace("https://www.ccbiblestudy.org/New Testament", "")
                            .replace("https://www.ccbiblestudy.org/Old Testament", "");
        const finalFilePath = `${window.location.origin}/ccbiblestudy${filePath}`;

        const response = await fetch(finalFilePath);
        const data = await response.text();
        const finalResult = extractContent(data);
        setCurrentResource(finalResult || resultText);
    };

    const formatText = (text) => {
        return text.split("壹、內容綱要").join("<p>壹、內容綱要</p>")
                    .split("貳、逐節詳解").join("<p>貳、逐節詳解</p>")
                    .split("叁、靈訓要義").join("<p>叁、靈訓要義</p>")
                    .split("〔呂振中譯〕").join("<br>〔呂振中譯〕<br>")
                    .split("〔原文字義〕").join("<br>〔原文字義〕<br>")
                    .split("〔文意註解〕").join("<br>〔文意註解〕<br>")
                    .split("〔話中之光〕").join("<br>〔話中之光〕<br>")
                    .split("【").join("<p>【")
                    .split("】").join("】</p>")
                    + "<p>。。。（前往原始網站查看更多）";
    };

    return (
        <EuiFlexGroup>
            {/* 左侧播放窗口 */}
            <EuiFlexItem grow={isPanelVisible ? 2 : 3} style={panelStyle}>
                <EuiPanel style={panelStyle}>
                    {currentSong ? (
                        currentSong.labels === "v" ? (
                        <div>
                            <h3 style={{ textAlign: 'center', margin: '10px 0' }}>{TranslateLanguage(localStorage.userLanguage, currentSong.name)}</h3>
                            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
                                <YouTube
                                    id="ytPlayer"
                                    videoId={currentSong.resourceId}
                                    className="yt-iframe"
                                    containerClassName="yt-container"
                                    opts={opts}
                                    onEnd={handleSongEnd}  // 添加此行来处理播放结束事件
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                    }}
                                />
                            </div>
                        </div>) : (
                                    // renderTextContent(currentSong)  // 渲染文本内容
                                    <div style={panelStyle}>
                                    <>
                                    {!(["拾穗", "註解", "例證", "綱目"].includes(currentSong.resourceType)) && (
                                        <>
                                        {(currentSong.resourceType === '經文') && (
                                            <div>
                                                <div style={textVerseContainerStyle}>
                                                    <span>{TranslateLanguage(localStorage.userLanguage, verses)}</span>
                                                </div>
                                                <div style={{...textTitleStyle, textAlign: getTextAlignment(currentSong) }}>
                                                    <EuiText>
                                                        <span>{TranslateLanguage(localStorage.userLanguage, currentResource)}</span>
                                                    </EuiText>
                                                </div>
                                            </div>
                                        )}
                                        {!(currentSong.resourceType === '經文') && (
                                            <div>
                                                <div style={textTitleStyle}>
                                                    <span>{TranslateLanguage(localStorage.userLanguage, currentSong.name)}</span>
                                                </div>
                                                <div style={textContainerStyleWithTitle}>
                                                    <EuiText>
                                                        <span>{TranslateLanguage(localStorage.userLanguage, currentResource)}</span>
                                                    </EuiText>
                                                </div>
                                            </div>
                                        )}
                                        </>
                                    )}
                                    {["拾穗", "註解", "例證", "綱目"].includes(currentSong.resourceType) && (
                                        <div style={textContainerStyle}>
                                            <EuiText>
                                                <span dangerouslySetInnerHTML={{__html: TranslateLanguage(localStorage.userLanguage, currentResource)}} />
                                            </EuiText>
                                        </div>
                                    )}
                                    </>
                                    <div style={{ textAlign: 'right', marginTop: '10px' }}>
                                        {currentSong.link && (
                                            <EuiButton 
                                                size="s" 
                                                fill 
                                                onClick={() => window.open(currentSong.link, '_blank')}
                                                style={{ backgroundColor: '#0077c2', color: '#fff' }}
                                            >
                                                {TranslateLanguage(localStorage.userLanguage, "鏈接來源")}
                                            </EuiButton>
                                        )}
                                    </div>
                                </div>
                            )
                    ) : (
                        <p>{TranslateLanguage(localStorage.userLanguage, "請點擊收藏項目播放")}</p>
                    )}
                </EuiPanel>
            </EuiFlexItem>

            {/* 面板切换按钮 */}
            <EuiFlexItem grow={false} style={{ zIndex: 1 }}>
                <EuiFlexGroup className="panel-toggle-button" direction="column" alignItems="center" justifyContent="center">
                    <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                            iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'}
                            onClick={togglePanel}
                            aria-label="Toggle Playlist Panel"
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem>

            {/* 右侧歌单及歌曲显示 */}
            {isPanelVisible && (
                <EuiFlexItem grow={1} style={{ height: '100vh', overflowY: 'auto' }}>
                    <EuiPanel style={{ width: '100%', textAlign: 'center', zIndex: 1 }}> {/* 确保整个面板宽度一致 */}
                        {/* 顶部Logo和标题部分 */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                cursor: 'pointer',
                                marginBottom: '20px',
                                width: '100%'
                            }}
                            onClick={() => navigate('/')}
                        >
                            <img src={logosmall} alt="Logo" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
                            <h2 style={{ margin: 0, textAlign: 'center' }}>{playlist ? `${TranslateLanguage(localStorage.userLanguage, "收藏單名")}：${playlist.name}` : '分享的收藏单'}</h2>
                        </div>
                        {/* 收藏单和收藏項目列表部分 */}
                        <div style={{ width: '100%' }}>
                            <EuiListGroup flush>
                                {playlist.playlistResources.map((song, index) => (
                                    <EuiListGroupItem
                                        key={song.resourceId}
                                        className={`song-item ${currentSong && currentSong.resourceId === song.resourceId ? 'playing-song' : ''}`}
                                        onClick={() => setCurrentSongIndex(index)}
                                        label={`${TranslateLanguage(localStorage.userLanguage, song.resourceType)}: ${
                                            // song.name.length > 12 ? TranslateLanguage(localStorage.userLanguage, song.name.substring(0, 12)) + '...' : TranslateLanguage(localStorage.userLanguage, song.name)
                                            TranslateLanguage(localStorage.userLanguage, song.name)
                                        }`}
                                        icon={<EuiIcon type={song.labels === "v" ? "play" : "document"} /> }
                                    />
                                ))}
                            </EuiListGroup>
                        </div>
                    </EuiPanel>
                </EuiFlexItem>
            )}
        </EuiFlexGroup>
    );
};

export default SharedFavoritePage;
