import React, { useState, useEffect, useRef } from "react";
import { EuiModal, EuiOverlayMask, EuiIcon, EuiButtonIcon } from '@elastic/eui';
import { marked } from "marked";
import {TranslateLanguage} from './KeywordTranslator';
import './ChatBot.css';
import micIcon from '../assets/CTB-Mic-P.png';

function ChatBotModal({ isVisible, closeModal }) {
  const [message, setMessage] = useState("");
  const [chats, setChats] = useState([{ role: TranslateLanguage(localStorage.userLanguage, "AI詩歌助手"), content: TranslateLanguage(localStorage.userLanguage, "您好，歡迎使用AI詩歌助手！") }]);
  const [reply, setReply] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const [threadId, setThreadId] = useState(null); 
  const chatWindowRef = useRef(null);
  const user = JSON.parse(sessionStorage.getItem('user')); // 获取用户信息
  const [username, setUsername] = useState(sessionStorage.getItem('user') ? user.username : "使用者")
  const inputRef = useRef(null);
  const recognitionRef = useRef(null);

  useEffect(() => {
    document.title = "Check The Bible";
  }, []);

  const sendMessage = async (text = message) => {
    if (!text.trim()) return;

    setChats([...chats, { role: "user", content: text }]);
    setMessage("");
    setIsTyping(true);
    // console.log("API URL:", process.env.REACT_APP_GptAPI_URL);

    try {
      const response = await fetch(`${process.env.REACT_APP_GptAPI_URL}`, {
      //const response = await fetch("http://localhost:5050/chat", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ message: text, threadId }),
      });

      if (!response.ok) throw new Error("Network response was not ok");

      const receivedThreadId = response.headers.get("x-thread-id");
      if (receivedThreadId && !threadId) {
        setThreadId(receivedThreadId);
      }

      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      let fullResponse = "";
      let firstChunkReceived = false;

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        fullResponse += value;
        if (!firstChunkReceived) {
          setIsTyping(false);
          firstChunkReceived = true;
        }
        TranslateLanguage(localStorage.userLanguage, fullResponse);
        setReply(marked.parse(fullResponse.replace(/\n/g, "<br />")));
      }

      setChats((prevChats) => [...prevChats, { role: TranslateLanguage(localStorage.userLanguage, "AI詩歌助手"), content: TranslateLanguage(localStorage.userLanguage, marked.parse(fullResponse)) }]);
    } catch (error) {
      console.error("Failed to fetch:", error);
      setIsTyping(false);
    }

    setReply("");
  };

  const handleQuickPromptClick = () => {
    const quickPrompt = "請建議一首快歌，兩首慢的詩歌";
    sendMessage(quickPrompt);
  };

  useEffect(() => {
    if (chatWindowRef.current) {
      chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
    }
  }, [chats, reply]);

  useEffect(() => {
    // 检查浏览器是否支持 SpeechRecognition API
    if (!('SpeechRecognition' in window || 'webkitSpeechRecognition' in window)) {
        console.warn("您的浏览器不支持语音识别功能");
        return;
    }

    // 初始化 SpeechRecognition 实例
    recognitionRef.current = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognitionRef.current.lang = 'zh-CN'; // 设置识别语言

    // 处理识别结果
    recognitionRef.current.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        inputRef.current.value = transcript;
        setMessage(transcript);
    };

    // 错误处理
    recognitionRef.current.onerror = (event) => {
        console.error('语音识别出错:', event.error);
    };
}, []);

const handleVoiceInput = () => {
    if (recognitionRef.current) {
        recognitionRef.current.start();
    }
};

  return (
    <>
      {isVisible && (
        <EuiOverlayMask>
          <EuiModal onClose={closeModal} style={{ width: '900px' }}>
            <header className="chat-header">
              <div className="chat-title">{TranslateLanguage(localStorage.userLanguage, "AI詩歌助手")}</div>
            </header>
            <div className="chat-window" ref={chatWindowRef} style={{ height: '400px', overflowY: 'scroll' }}>
              {chats.map((chat, index) => (
                <div key={index} className={chat.role === "user" ? "user-msg" : "assistant-msg"}>
                  <b>{chat.role === "user" ? TranslateLanguage(localStorage.userLanguage, username) : chat.role.toUpperCase()}:</b> 
                  <span dangerouslySetInnerHTML={{ __html: chat.content }}></span>
                </div>
              ))}
              {isTyping && (
                <div className="typing-indicator">
                  <b>{TranslateLanguage(localStorage.userLanguage, "AI詩歌助手:")}</b> <em>分析中。。。</em>
                </div>
              )}
              {reply && <div className="assistant-msg"><b>{TranslateLanguage(localStorage.userLanguage, "AI詩歌助手:")}</b> <span dangerouslySetInnerHTML={{ __html: reply }}></span></div>}
            </div>
            {/* <div className="quick-prompt" onClick={handleQuickPromptClick} style={{ textAlign: "left", cursor: "pointer", color: "#4a90e2", marginBottom: "10px", marginLeft: "10px" }}>
              請建議一首快歌，兩首慢的詩歌
            </div> */}
            <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }} className="chat-input">
            <div className="input-wrapper">
              <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder={TranslateLanguage(localStorage.userLanguage, "請向我提問，例如推薦與信心相關的詩歌")}
                ref={inputRef}
                className="input-field"
              />
              <EuiIcon
                    type={micIcon}
                    onClick={handleVoiceInput}
                    className="voice-icon"
                    aria-label="Start voice input"
              />
              </div>
              <button type="submit">{TranslateLanguage(localStorage.userLanguage, "發送")}</button>
            </form>
          </EuiModal>
        </EuiOverlayMask>
      )}
    </>
  );
}

export default ChatBotModal;