import React, { useEffect, useState, useRef } from 'react';
import YouTube from 'react-youtube';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { EuiPanel, EuiFlexGroup, EuiFlexItem, EuiButtonIcon, EuiListGroup, EuiListGroupItem, EuiButtonEmpty, EuiSwitch } from '@elastic/eui';
import logosmall from '../assets/CTBLogoCircle-Small.png';
import '../style.css';
import { TranslateLanguage } from './KeywordTranslator';
import axios from 'axios';
import { useAlert } from './AlertProvider';

const SharedPlaylistPage = () => {
    const { playlistId } = useParams();
    const [playlist, setPlaylist] = useState(null);
    const [currentSong, setCurrentSong] = useState({ id: '', name: '' });
    const [currentSongIndex, setCurrentSongIndex] = useState(0);
    const [isPanelVisible, setIsPanelVisible] = useState(true);
    const navigate = useNavigate();
    const [isPortrait, setIsPortrait] = useState(window.innerHeight > window.innerWidth);
    const playerRef = useRef(null); // 用 useRef 来存储 player 实例
    const [ytApiReady, setYtApiReady] = useState(false);
    const currentSongRef = useRef(currentSong);   // 使用 useRef 存储最新的 currentSong
    const currentSongIndexRef = useRef(currentSongIndex);   
    const [initSong, setInitSong] = useState(null);
    const [showHint, setShowHint] = useState(true); // 提示文字的显示状态
    const [isLoopEnabled, setIsLoopEnabled] = useState(true); // 控制連續播放的状态
    const isLoopEnabledRef = useRef(isLoopEnabled); 
    const showAlert = useAlert();

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    useEffect(() => {
        // 根据是否为 iOS 设备设置提示文字的显示状态
        if (isIOS) {
          setShowHint(true);  // 如果是 iOS 设备，显示提示文字
        } else {
          setShowHint(false); // 如果不是 iOS 设备，隐藏提示文字
        }
        const script = document.createElement('script');
        script.src = "https://www.youtube.com/iframe_api";
        script.async = true;
        script.onload = () => {
          // console.log("YouTube IFrame API script loaded");
      
          // Check if the YT object is already available
          if (window.YT && window.YT.Player) {
            // console.log("YT object is available, API is ready");
            setYtApiReady(true);
          }
        };
        document.body.appendChild(script);
      
        window.onYouTubeIframeAPIReady = () => {
          // console.log("YouTube IFrame API is ready");
          setYtApiReady(true);
        };
      
        // Fallback if the API doesn't signal readiness
        setTimeout(() => {
          if (window.YT && window.YT.Player && !ytApiReady) {
            console.log("Fallback: YT object available, setting ytApiReady to true");
            setYtApiReady(true);
          }
        }, 5000); // Fallback after 5 seconds
      }, []);
    
      // 当 ytApiReady 和 currentSong 都准备好时初始化播放器
      useEffect(() => {
        if (ytApiReady && initSong && !playerRef.current) {
          playerRef.current = new YT.Player('youtube-player', {
            height: '80%',
            width: '90%',
            videoId: initSong.resourceId,
            playerVars: {
              'autoplay': isIOS ? 0 : 1, // iOS 不自动播放，其他设备自动播放
              'controls': 1, // 显示控制条
              'mute': isIOS ? 1 : 0, // iOS 静音，其他设备不静音
            },
            events: {
              'onStateChange': onPlayerStateChange,
              'onError': onPlayerError // 添加错误处理
            }
          });
          setCurrentSong(initSong);
        }
      }, [ytApiReady, initSong]);

    useEffect(() => {
        if (playerRef.current && currentSong) {
          // console.log('Next Song (after update):', currentSong);
          // if (playerRef.current.getPlayerState && playerRef.current.getPlayerState() !== -1) {
          if (playerRef.current.getPlayerState) {
            playerRef.current.loadVideoById(currentSong.resourceId);
            if (isIOS){
              playerRef.current.unMute(); // 解除静音
            }
          }
        }
      }, [currentSong]);

    // 同步更新 currentSongRef
    useEffect(() => {
        currentSongRef.current = currentSong;
    }, [currentSong]);

    useEffect(() => {
        currentSongIndexRef.current = currentSongIndex;
    }, [currentSongIndex]);

    useEffect(() => {
        const player = document.getElementById('youtube-player');
        if (player) {
          player.className = isPanelVisible ? 'radio-player' : 'radio-player-full';
        }
    }, [isPanelVisible]);

    useEffect(() => {
        isLoopEnabledRef.current = isLoopEnabled;  // 每次状态更新时更新 ref
    }, [isLoopEnabled]);

    const SongItem = ({ song, currentSong, setCurrentSongIndex, index }) => {
        const songItemRef = useRef(null);

        useEffect(() => {
            if (songItemRef.current && currentSong && currentSong.resourceId === song.resourceId) {
                songItemRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                });
            }
        }, [currentSong, song]);

        const handleSongClick = () => {
            // 如果当前点击的歌曲和正在播放的歌曲相同且播放器已暂停
            if (currentSong === song) { 
              if (playerRef.current.getPlayerState() === 0){ // 0 播放結束
                playerRef.current.seekTo(0);  // 将视频进度设置到开头
                setTimeout(() => playerRef.current.playVideo(), 10); // 触发重新播放
              }
              else if (playerRef.current.getPlayerState() === 2){ // 2 表示暂停状态
                playerRef.current.playVideo();
              } 
            } else {
                // 否则设置为当前歌曲并开始播放
                setCurrentSongIndex(index);
                setCurrentSong(playlist.playlistResources[index]);
            }
        };

        return (
            <div ref={songItemRef}>
                <EuiListGroupItem
                    key={song.resourceId}
                    className={`song-item ${currentSong && currentSong.resourceId === song.resourceId ? 'playing-song' : ''}`}
                    label={TranslateLanguage(localStorage.userLanguage, song.name)}
                    onClick={handleSongClick}
                    iconType="play"
                />
            </div>
        );
    };

    const onPlayerStateChange = (event) => {
        switch (event.data) {
          case YT.PlayerState.PLAYING:
            // setPlaying(true); // 更新状态为播放中
            if (isIOS){
                playerRef.current.unMute(); // 解除静音
              }
            break;
          case YT.PlayerState.PAUSED:
            // setPlaying(false); // 更新状态为暂停
            break;
          case YT.PlayerState.ENDED:
            handleSongEnd();
            break;
          default:
            break;
        }
      };
    
      // 处理错误事件
      function onPlayerError(event) {
          const errorCode = event.data;
          switch (errorCode) {
          case 2:
              showAlert(TranslateLanguage(localStorage.userLanguage, "視頻ID無效，請檢查視頻ID。"));
              break;
          case 5:
              showAlert(TranslateLanguage(localStorage.userLanguage, "視頻無法播放，可能是格式問題。"));
              break;
          case 100:
              showAlert(TranslateLanguage(localStorage.userLanguage, "視頻已被刪除或設為私人視頻。"));
              break;
          case 101:
          case 150:
              showAlert(TranslateLanguage(localStorage.userLanguage, "視頻不允許嵌入播放。"));
              break;
          default:
              showAlert(TranslateLanguage(localStorage.userLanguage, "視頻播放出錯，請嘗試其他視頻。"));
              break;
          }
      
          // 尝试播放下一首或其他逻辑
          // handleSongEnd();
      }

    function fetchSharedPlaylist() {

        const shareCode = window.location.pathname.split("/").pop(); // 从 URL 中提取分享代码

      // 发起 Axios 请求，明确表示不需要 `Authorization` 头
        axios.get(`/api/playlists/sharedplaylist/${shareCode}`, {
          headers: {}  // 覆盖全局拦截器中的 Authorization 头
        })
            .then(response => {
                const data = response.data;
                setPlaylist(data);
                setInitSong(data.playlistResources[0]);
            })
            .catch(error => console.error('加载分享的歌单时出错:', error));
    }

    function setViewportDimensions() {
      let height = 44;
      let calwidth;
      let calheight;
      calwidth = window.innerWidth - 30;
      calheight = window.innerHeight - height;
      if (calwidth*9/16 > calheight)
          document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - height}px`);
      else
          document.documentElement.style.setProperty('--viewport-height', `${calwidth*9/16}px`);
      document.documentElement.style.setProperty('--viewport-width', `${window.innerWidth - 20}px`);
      // document.documentElement.style.setProperty('--viewport-height', `${window.innerHeight - height}px`);
  }
  
  // 初始化时设置值
  setViewportDimensions();
  
  // 监听窗口大小变化事件
  window.addEventListener('resize', setViewportDimensions); 

    useEffect(() => {
        const handleResize = () => {
          setIsPortrait(window.innerHeight > window.innerWidth);
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, []);

    useEffect(() => {
        fetchSharedPlaylist();
    }, [playlistId]);

    function enterFullscreen() {
      const playerElement = playerRef.current.getIframe();
      if (isIOS) {
        showAlert(TranslateLanguage(localStorage.userLanguage, "在 iOS 設備上，請使用播放器右下角的全屏按鈕進入全屏模式。"));
      } else if (playerElement.requestFullscreen) {
          playerElement.requestFullscreen();
      } else if (playerElement.mozRequestFullScreen) { // Firefox
          playerElement.mozRequestFullScreen();
      } else if (playerElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
          playerElement.webkitRequestFullscreen();
      } else if (playerElement.msRequestFullscreen) { // IE/Edge
          playerElement.msRequestFullscreen();
      }
    }

    const togglePanel = () => {
        // setIsPanelVisible(!isPanelVisible);
        enterFullscreen();
    };

    // const handleSongEnd = () => {
    //     if (playlist && playlist.playlistResources) {
    //         const nextIndex = (currentSongIndexRef.current + 1) % playlist.playlistResources.length;
    //         setCurrentSongIndex(nextIndex);
    //         setCurrentSong(playlist.playlistResources[nextIndex]);
    //     }
    // };

    // 处理播放结束事件，自动播放下一首歌
    const handleSongEnd = () => {

      if (playlist && playlist.playlistResources) {
        // 如果开启了循环播放
        if (isLoopEnabledRef.current) {
            // const nextIndex = (currentSongIndexRef.current + 1) % playlist.playlistResources.length; // 循环播放
            // setCurrentSongIndex(nextIndex);
            // setCurrentSong(playlist.playlistResources[nextIndex]);
            // //如果只有一首詩歌，下一首和當前首index一樣
            // if (nextIndex == currentSongIndexRef.current){
            //     playerRef.current.seekTo(0);  // 将视频进度设置到开头
            //     setTimeout(() => playerRef.current.playVideo(), 10); // 触发重新播放
            // }
            if (currentSongIndexRef.current < playlist.playlistResources.length - 1) {
              const nextIndex = currentSongIndexRef.current + 1; // 播放下一首
              setCurrentSongIndex(nextIndex);
              setCurrentSong(playlist.playlistResources[nextIndex]);
            } else {
                // 最后一首播放完后，停止播放
                // setCurrentSong(null); // 停止播放，防止为空时报错
                playerRef.current.pauseVideo();
            }
        } else {
            playerRef.current.pauseVideo();
        }
      }
    };

    if (!playlist) {
        return <div>{TranslateLanguage(localStorage.userLanguage, "加載中...")}</div>;
    }

    const handleLoopToggle = (e) => {
      // console.log('Loop toggled, new state:', e.target.checked);
      setIsLoopEnabled(e.target.checked);
    };

    return (
        <EuiFlexGroup className="responsive-flex-group">
            {/* 左侧播放窗口 */}
            <EuiFlexItem grow={isPanelVisible ? 2 : 3} style={{ position: 'sticky', top: 0,  zIndex: 100 }} className={isPanelVisible ? "left_video_item":  "left_video_item_full"}>
                <EuiPanel
                  style={{
                    display: 'flex',
                    flexDirection: 'column', // 垂直排列子元素
                    justifyContent: 'center', // 水平居中对齐
                    alignItems: 'center', // 子元素在垂直方向居中
                    padding: '5px', // 可根据需要调整面板的内边距
                    }}  
                >
                    {currentSong ? (
                        <>
                        {/* <h3 style={{ textAlign: 'center', margin: '10px 0' }}>{TranslateLanguage(localStorage.userLanguage, currentSong.name)}</h3> */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', margin: '10px 0', width: '100%' }}>
                            {/* 歌曲标题 */}
                            <h3 style={{ flex: 1, textAlign: 'center', margin: 0 }}>
                                {TranslateLanguage(localStorage.userLanguage, currentSong.name)}
                            </h3>
                            
                            {/* 按钮 */}
                            <EuiButtonEmpty iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'} onClick={togglePanel} style={{ marginLeft: 'auto' }}>
                                {/* {isPortrait ? TranslateLanguage(localStorage.userLanguage, '全屏') : ''} */}
                                {TranslateLanguage(localStorage.userLanguage, '全屏')}
                            </EuiButtonEmpty>
                        </div>
                        <div id="youtube-player" className = 'playlist-player'></div>
                        <div style={{ 
                                display: 'flex', 
                                marginTop: '10px',
                                justifyContent: 'space-around', 
                                alignItems: 'center', 
                                width: '100%'  // 让按钮组与播放器同宽 
                                }}>
                                    <EuiSwitch
                                        label={TranslateLanguage(localStorage.userLanguage, "連續播放")}
                                        checked={isLoopEnabled}
                                        onChange={handleLoopToggle} // 切换循环播放开关
                                    />
                        </div>
                        </>
                    ) : (
                        <p>{TranslateLanguage(localStorage.userLanguage, "請點擊歌曲播放")}</p>
                    )}
                </EuiPanel>
            </EuiFlexItem>

            {/* 面板切换按钮 */}
            {/* <EuiFlexItem grow={false}  style={{ zIndex: 1 }} className='center_toggle_button'>
                <EuiFlexGroup className="panel-toggle-button" direction="column" alignItems="center" justifyContent="center">
                    <EuiFlexItem grow={false}>
                        <EuiButtonIcon
                            iconType={isPanelVisible ? 'arrowRight' : 'arrowLeft'}
                            onClick={togglePanel}
                            aria-label="Toggle Playlist Panel"
                        />
                    </EuiFlexItem>
                </EuiFlexGroup>
            </EuiFlexItem> */}

            {/* 右侧歌单及歌曲显示 */}
            {isPanelVisible && (
                <EuiFlexItem grow={1} className="rigth_song_info" style={{ height: '100vh', overflowY: 'auto' }}>
                    <EuiPanel style={{ width: '100%', textAlign: 'center', zIndex: 1 }}> {/* 确保整个面板宽度一致 */}
                        {/* 顶部Logo和标题部分 */}
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                cursor: 'pointer',
                                marginBottom: '20px',
                                width: '100%'
                            }}
                            onClick={() => navigate('/')}
                        >
                            <img src={logosmall} alt="Logo" style={{ width: '50px', height: '50px', marginBottom: '10px' }} />
                            <h2 style={{ margin: 0, textAlign: 'center' }}>{playlist ? `${TranslateLanguage(localStorage.userLanguage, "歌單名")}：${playlist.name}` : '分享的歌单'}</h2>
                        </div>
                        {/* 歌单和歌曲列表部分 */}
                        <div style={{ width: '100%', overflowY: 'auto' }}>
                            <EuiListGroup flush>
                                {playlist.playlistResources.map((song, index) => (
                                    <SongItem
                                        key={index}
                                        song={song}
                                        currentSong={currentSong}
                                        setCurrentSongIndex={setCurrentSongIndex}
                                        index={index}
                                    />
                                ))}
                            </EuiListGroup>
                        </div>
                    </EuiPanel>
                </EuiFlexItem>
            )}
        </EuiFlexGroup>
    );
};

export default SharedPlaylistPage;
