import React ,{useState, useEffect} from 'react'
import TranslateInput, {TranslateLanguage} from '../components/KeywordTranslator';
import '../style.css';

import {
    EuiText,
    EuiTextColor,
    EuiSelect, 
    EuiSpacer,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiButton,
    EuiButtonIcon
  } from '@elastic/eui';

function Verse(props) {
    var versenumber =  props.versenumber;
    var language = props.language;
    const [resultVerse, setResultVerse] = useState("");
    const [version, setVersion] = useState("unv");
    const [book, setBook] = useState(props.book);
    const [bookname, setBookName] = useState(props.bookname);
    const [chapter, setChapter] = useState(props.chapter);
    const [playKey, setPlayKey] = useState("")
    const [prevButton, setPrevButton] = useState(true);
    const [nextButton, setNextButton] = useState(true);
    const playApiURL = process.env.REACT_APP_PlayApiURL;
    const apiKey = process.env.REACT_APP_APIKey;
    const [loading, setLoading] = useState(true); // 初始化为加载状态

    // const apiURL = "https://bible.fhl.net/json/qb.php?"
    const apiURL = "/api/bible/verses?"
    const options = [
      { value: 'unv', text: '(和合本 CUV）' },
      // { value: 'rcuv', text: '和合本2010 (RCUV)' },
      // { value: 'cumv', text: '官話和合本 (CYMV)' },
      // { value: 'tcv2019', text: '現代中文譯本2019版 (TCV2019)' },
      // { value: 'lcc', text: '呂振中譯本 （LCC)' },   
      // { value: 'cuwv', text: '文理和合本 （CUWV)' },   
      // { value: 'jp', text: '日語聖經 （JP)' },
      { value: 'kjv', text: 'King James （KJV)' },
      // { value: 'esv', text: 'English Standard Version (ESV)' },
      // { value: 'asv', text: 'American Standard Version (ASV)' },
      // { value: 'web', text: 'World English Bible (WEB)' }
    ];
    const bookMax =[{book: "創", max:50},{book: "出", max:40},{book: "利", max:27},{book: "民", max:36},{book: "申", max:34},{book: "書", max:24},{book: "士", max:21},{book: "得", max:4},
                  {book: "撒上", max:31},{book: "撒下", max:24},{book: "王上", max:22},{book: "王下", max:25},{book: "代上", max:29},{book: "代下", max:36},{book: "拉", max:10},{book: "尼", max:13},
                  {book: "斯", max:10},{book: "伯", max:42},{book: "詩", max:150},{book: "箴", max:31},{book: "傳", max:12},{book: "歌", max:8},{book: "賽", max:66},{book: "耶", max:52},{book: "哀", max:5},
                  {book: "結", max:48},{book: "但", max:12},{book: "何", max:14},{book: "珥", max:3},{book: "摩", max:9},{book: "俄", max:1},{book: "拿", max:4},{book: "彌", max:7},{book: "鴻", max:3},
                  {book: "哈", max:3},{book: "番", max:3},{book: "該", max:2},{book: "亞", max:14},{book: "瑪", max:4},{book: "太", max:28},{book: "可", max:16},{book: "路", max:24},{book: "約", max:21},
                  {book: "徒", max:28},{book: "羅", max:16},{book: "林前", max:16},{book: "林後", max:13},{book: "加", max:6},{book: "弗", max:6},{book: "腓", max:4},{book: "西", max:4},{book: "帖前", max:5},
                  {book: "帖後", max:3},{book: "提前", max:6},{book: "提後", max:4},{book: "多", max:3},{book: "門", max:1},{book: "來", max:13},{book: "雅", max:5},{book: "彼前", max:5},
                  {book: "彼後", max:3},{book: "約一", max:5},{book: "約二", max:1},{book: "約三", max:1},{book: "猶", max:1},{book: "啟", max:22}]

    const [value, setValue] = useState(options[0].value);
    const [play, setPlay] = useState(false);

    useEffect(()=>{
        loadVerse();
        loadPlayKey(chapter);
    },[])
 
    const loadVerse = async () => {
        setLoading(true);
        var resultVerseText = "";

        await fetch(apiURL + "version=" + version + "&chineses=" + book + "&chap=" + chapter)
        .then(function(response){
            return(response.json())
        })
        .then(function(data){
            var results = JSON.parse(JSON.stringify(data))
            for(var i = 0; i < results.record.length; i++) {
              if(versenumber != results.record[i].sec) {
                resultVerseText = resultVerseText + "<EuiText>" + results.record[i].sec + " " + TranslateLanguage(language,results.record[i].bible_text)  + "</EuiText><br/>"
              }else{
                resultVerseText = resultVerseText + "<EuiText><em>" + results.record[i].sec + " " + TranslateLanguage(language,results.record[i].bible_text)  + "</em></EuiText><br/>"
              }
            }
            //console.log(JSON.stringify(results, null, 2))  
        })

        setResultVerse(resultVerseText);
        setLoading(false);
        var getBook = book;
        var maxVerse = bookMax.find( ({ book }) => book === getBook );  
        (maxVerse.max < (parseInt(chapter) +1)) && setNextButton(false);
        ((parseInt(chapter) -1) <= 0) && setPrevButton(false);
    }

    const apiResult = async (version, selectChapter) =>{
      var apiVerseResult;
      await fetch(apiURL + "version=" + version + "&chineses=" + book + "&chap=" + selectChapter)
      .then(function(response){
          return(response.json())
      })
      .then(function(data){
          apiVerseResult = JSON.parse(JSON.stringify(data))
          //console.log(JSON.stringify(apiVerseResult, null, 2))  
      })
      return apiVerseResult;
    }

    const getAllResult =(version, selectChapter) =>{
      return Promise.all([apiResult("unv", selectChapter), apiResult(version,selectChapter)])
    }

    const onChange = (e) => {
      setValue(e.target.value);
      setVersion(e.target.value)
      var selectVersion = e.target.value;
      refreshResult(selectVersion, chapter);
    }

    const refreshResult = (selectVersion, selectChapter)=>{
      var showBaseVersion = (selectVersion != "unv") ? "(CUV)" : "";
      var resultVerseText = "";
      setLoading(true);
      getAllResult(selectVersion, selectChapter)
        .then(([baseVerseResult, compVerseResult]) => { 
          for(var i = 0; i < baseVerseResult.record.length; i++) {
            if(versenumber != baseVerseResult.record[i].sec) {
              resultVerseText = resultVerseText + "<EuiText>" + baseVerseResult.record[i].sec + " " + TranslateLanguage(language,baseVerseResult.record[i].bible_text)  + showBaseVersion + "</EuiText><br/>";
              resultVerseText = (selectVersion != "unv") ? resultVerseText + "<EuiText>" +  compVerseResult.record[i].sec + " " + TranslateLanguage(language,compVerseResult.record[i].bible_text)  + " (" + selectVersion.toUpperCase()  + ") " + "</EuiText><br/><br/>" : resultVerseText;
            }else{
              resultVerseText = resultVerseText + "<EuiText><em>" + baseVerseResult.record[i].sec + " " + TranslateLanguage(language,baseVerseResult.record[i].bible_text)  + showBaseVersion + "</em></EuiText><br/>";
              resultVerseText = (selectVersion != "unv") ? resultVerseText + "<EuiText><em>" + compVerseResult.record[i].sec + " " + TranslateLanguage(language,compVerseResult.record[i].bible_text)  +  " (" + selectVersion.toUpperCase() + ") " + "</em></EuiText><br/><br/>" : resultVerseText; 
            }
        }
          setResultVerse(resultVerseText);
          setLoading(false);
        })
    
    };   
    
    const nextChapter=()=>{
      var newChapter = (parseInt(chapter) +1).toString();
      var getBook = book;
      var maxVerse = bookMax.find( ({ book }) => book === getBook);
      (maxVerse.max < (parseInt(chapter) +2)) && setNextButton(false);
      setPrevButton(true);
      setChapter(newChapter);
      refreshResult(version, newChapter);
      setPlay(false)
      loadPlayKey(newChapter);
      versenumber = 9999;
    }

    const prevChapter=()=>{
      var newChapter = (parseInt(chapter)-1).toString();
      ((parseInt(chapter) -2) <= 0) && setPrevButton(false);
      setNextButton(true);
      setChapter(newChapter);
      refreshResult(version, newChapter);
      setPlay(false)
      loadPlayKey(newChapter);
      versenumber = 9999;
    }

    const loadPlayKey = async (selectChapter) => {
      await fetch(playApiURL,{
        method:"POST",
        headers:{
            "Content-Type":"application/json, charset=UTF-8",
            "Authorization": "Bearer search-" + apiKey
        },
        body:JSON.stringify({
            query:book,
            filters: {"all": [{book: book}, {chapter: selectChapter}]}, 
            page:{size: 1000},
            sort:{id: "asc"}
        })
    })
    .then(function(response){
        return(response.json())
    })
    .then(function(data){
        var results = JSON.parse(JSON.stringify(data));
        if (results.results){
          var resultKey = results.results[0].key.raw;
          if(!resultKey){resultKey = results.results[0].key2.raw;}
           setPlayKey("http://docs.google.com/uc?export=open&id=" + resultKey);   
        }
    })
    }

    const onPlay = (e) => {
      setPlay(true)
    };

    return (
        <>
            {loading ? (
                <p>{TranslateLanguage(language,"正在加载中...")}</p> // 加载时显示提示文本
            ) : (
                <>
          <EuiFlyoutHeader hasBorder>
            <EuiFlexGroup wrap={false} gutterSize="xs" responsive={false}>
              <EuiFlexItem grow={false}>
              <EuiTitle size="m">
                <h2 id="flyoutTitle">{TranslateLanguage(language,bookname)} {chapter}</h2>
              </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem><EuiSpacer size="l"></EuiSpacer></EuiFlexItem>
              <EuiFlexItem grow={false}>
              {(prevButton) && <EuiButtonIcon
                display="base"
                iconType="arrowLeft"
                size="s"
                aria-label="上一章"
                onClick={()=> prevChapter()}
              />}
              </EuiFlexItem>
              <EuiFlexItem grow={false}><EuiSpacer size="m" /></EuiFlexItem>
              <EuiFlexItem grow={false} >           
              {(nextButton) && <EuiButtonIcon
                display="base"
                iconType="arrowRight"
                size="s"
                aria-label="下一章"
                onClick={()=> nextChapter()}
              />}
              </EuiFlexItem>
        
              </EuiFlexGroup>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>

            <EuiText>{TranslateLanguage(language, "對照經文版本：")} </EuiText>
              <EuiSelect
                id="selectVersion"
                options={options}
                value={value}
                onChange={(e) => onChange(e)}
              />
              <EuiSpacer></EuiSpacer>
              <EuiText><span dangerouslySetInnerHTML={{__html:resultVerse}} /></EuiText>

          </EuiFlyoutBody>
          <EuiFlyoutFooter>
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                {(play) && <audio id="PlayVerse" autoPlay controls onEnded={() => nextChapter()} controlsList="nodownload">
                <source src={playKey} type="audio/mp3" />
                Your browser does not support the audio element.
                </audio>}    
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  {(!play) && <EuiButton fill onClick={(e) => onPlay(e)}>
                  {TranslateLanguage(language, "朗讀")}
                  </EuiButton>}
                </EuiFlexItem>
              </EuiFlexGroup>
          </EuiFlyoutFooter>
          </>
            )}
        </>
    )
}

export default Verse
