import React, { useState, useEffect, memo } from 'react';
import { EuiModal, EuiModalHeader, EuiModalBody, EuiModalFooter, EuiButton, EuiSelect } from '@elastic/eui';
import {TranslateLanguage} from './KeywordTranslator';
import axios from 'axios';
import { useAlert } from './AlertProvider';

const RadioModal = memo(({ userId, playlistId, playlisttype, closeModal }) => {
    const [selectedRadiolistId, setSelectedRadioId] = useState('');
    const [playlists, setPlaylists] = useState([]);
    const showAlert = useAlert();
    
    //獲取電台列表
    useEffect(() => {
        axios.get(`/api/playlists`, {
            params: {
                type: playlisttype,
                userId: userId
            }
        })
        .then(response => {
            const data = response.data;
            // 如果 data 不是数组，则将其设置为空数组
            if (!Array.isArray(data)) {
                console.error('Fetched playlists data is not an array:', data);
                setPlaylists([]);
            } else {
                setPlaylists(data);
                // 默认选择第一个電台，
                if (data.length > 0) {
                    setSelectedRadioId(data[0].id);
                }
            }
        })
        .catch(error => {
            console.error('Error fetching playlists:', error);
        });
    }, [userId]);

    const handlePlaylistToRadio = () => {
        if (selectedRadiolistId) {
            const requestBody = {
                UserId: userId,
                PlaylistId: playlistId
            };

            axios.post(`/api/playlists/${selectedRadiolistId}/save-to-radio`, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                    // Authorization 头由全局拦截器处理
                }
            })
            .then(response => {
                if (response.status === 200) { // 可以根据具体的状态码判断
                    // console.log("Playlist saved to radio successfully");
                    showAlert(TranslateLanguage(localStorage.userLanguage, "保存成功"));
                } else {
                    // console.error("Failed to save playlist to radio");
                    showAlert(TranslateLanguage(localStorage.userLanguage, "保存失敗"));
                }
                closeModal(); // 关闭模态框
            })
            .catch(error => {
                console.error("Error saving playlist to radio:", error);
            });
        }
    };

    return (
        <EuiModal onClose={closeModal}>
            <EuiModalHeader>{TranslateLanguage(localStorage.userLanguage, "選擇電台")}</EuiModalHeader>
            <EuiModalBody>
                <EuiSelect
                    options={playlists.map(playlist => ({
                        value: playlist.id,
                        text: playlist.name
                    }))}
                    value={selectedRadiolistId}
                    onChange={e => setSelectedRadioId(e.target.value)}
                />
            </EuiModalBody>
            <EuiModalFooter>
                <EuiButton onClick={handlePlaylistToRadio} fill>{TranslateLanguage(localStorage.userLanguage, "保存")}</EuiButton>
            </EuiModalFooter>
        </EuiModal>       
    );
});

export default RadioModal;
