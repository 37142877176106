import React, { useEffect, useState } from 'react';
import {
    EuiText,
    EuiPanel,
    EuiSelect,
    EuiFlexGroup,
    EuiFlexItem,
    EuiFieldText,
    EuiFormRow,
    EuiTextAlign,
    EuiButton
  } from '@elastic/eui';
import axios from 'axios';
import { jwtDecode } from "jwt-decode";
import '../style.css';

const DataAnalysis = () => {
  const [selectedUser, setSelectedUser] = useState(null); // 用于存储选中的用户信息
  const [errorMessage, setErrorMessage] = useState(''); // 用于显示错误信息
  const [loading, setLoading] = useState(true); // 用于加载状态
  const [sortKey, setSortKey] = useState('id'); // 用于存储排序方式
  const [searchTerm, setSearchTerm] = useState(''); // 用于存储搜索关键词
  const [totalCount, setTotalCount] = useState(0);
  const [songCounts, setSongCounts] = useState([]);
  const [songPlaylists, setSongPlaylists] = useState([]);

  // 加载用户列表
  useEffect(() => {
    const token = sessionStorage.getItem("token");  // 从 localStorage 中获取 token
    let userRole = "";
    if (token) {
        const decodedToken = jwtDecode(token);  // 解码 token
        // console.log(decodedToken);  // 检查 token 中的内容，包括角色信息
        userRole = decodedToken.role;  // 获取角色信息
    }

    // 只有管理员才能获取用户列表
    if (userRole !== 'Admin') {
      setErrorMessage('Access denied. Only admins can view this page.');
      setLoading(false);
      return;
    }

    axios.get('/api/playlists/song-appearance-count')
    .then(response => {
      setSongCounts(response.data);  // 设置返回的数据
      setTotalCount(response.data.length)
      setLoading(false); // 加载完成
    })
    .catch(error => {
      console.error('获取诗歌出现次数时出错:', error);
      setLoading(false); // 加载完成
    });
  }, []);

  // 根据搜索关键词过滤列表
  const filteredUsers = songCounts.filter((user) =>
    user.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 对过滤后的用户列表进行排序
  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (sortKey === 'count') {
        return b.count - a.count;
    } else if (sortKey === 'name') {
        return a.name.localeCompare(b.name);
    }
    return 0;
  });

  if (loading) {
    return <p>Loading...</p>;  // 显示加载中的提示
  }

  if (errorMessage) {
    return <p>{errorMessage}</p>;  // 显示错误信息
  }

  // 点击某个用户时显示其详细信息

    const handleUserClick = (user) => {
        setSelectedUser(user);
        axios.get(`/api/playlists/song-playlists?resourceId=${user.resourceId}`)
            .then(response => {
            setSongPlaylists(response.data);
            })
            .catch(error => {
            console.error('加载诗歌歌单信息时出错:', error);
            });
    };

  return (
    <EuiFlexGroup>
    {/* 左侧用户列表 */}
    <EuiFlexItem grow={false} className="song-list-container">
      <EuiPanel paddingSize="s">
        <EuiFormRow label={`歌单中的诗歌列表 共${totalCount}首`}>
          <EuiSelect
            options={[
              { value: 'name', text: '按名称排序' },
              { value: 'count', text: '按次数排序' }
            ]}
            value={sortKey}
            onChange={(e) => setSortKey(e.target.value)}
          />
        </EuiFormRow>

        {/* 搜索框 */}
        <input
            type="text"
            placeholder="搜索詩歌..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />

        <ul className="song-list">
            {sortedUsers.map(user => (
              <li key={user.resourceID} 
                style={{ textAlign: 'left' }}
                onClick={() => handleUserClick(user)}
                >
                  {user.name} (次数: {user.count})
              </li>
            ))}
        </ul>
      </EuiPanel>
    </EuiFlexItem>

    {/* 右侧详细信息编辑 */}
    <EuiFlexItem>
    {selectedUser ? (
        <EuiPanel paddingSize="l">
            <EuiTextAlign textAlign="center">
                <h3>查看诗歌: {selectedUser.name} {selectedUser.engineID}</h3>
            </EuiTextAlign>
            {/* 显示该诗歌出现的歌单信息 */}
            {songPlaylists.length > 0 ? (
                <ul>
                {songPlaylists.map((playlist, index) => (
                    <li key={index}>
                    歌单名称: {playlist.playlistName}，所属用户: {playlist.userName}，播放次数: {playlist.playCount}
                    </li>
                ))}
                </ul>
            ) : (
                <p>该诗歌未在任何歌单中出现。</p>
            )}
        </EuiPanel>
    ) : (
        <EuiText>请选择一首詩歌以查看详细信息。</EuiText>
    )}
    </EuiFlexItem>
    </EuiFlexGroup>
)};

export default DataAnalysis;