import React, { useState } from 'react';
import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiModalFooter, EuiButton, EuiFieldText, EuiForm, EuiFormRow, EuiCallOut, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { useModal } from '../ModalContext';
import '../style.css';
import {TranslateLanguage} from './KeywordTranslator';

const ResetPasswordModal = () => {
  const { isResetPasswordModalVisible, setResetPasswordModalVisible } = useModal();
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const closeModal = () => {
    // 清除表单内容
    setEmail('');
    setError('');
    setSuccess('');
    setResetPasswordModalVisible(false);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      const response = await fetch('/api/users/send-reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        try {
          const errorJson = JSON.parse(errorText);
          setError(TranslateLanguage(localStorage.userLanguage, errorJson.message) || TranslateLanguage(localStorage.userLanguage, "出錯"));
        } catch {
          setError(errorText);
        }
      } else {
        setSuccess(TranslateLanguage(localStorage.userLanguage, "重設密碼鏈接已發送到您的郵箱"));
      }
    } catch (error) {
      setError(TranslateLanguage(localStorage.userLanguage, "出錯：") + error.message);
    }
  };

  if (!isResetPasswordModalVisible) {
    return null;
  }

  return (
    <EuiModal onClose={closeModal}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>{TranslateLanguage(localStorage.userLanguage, "重設密碼")}</EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm component="form" onSubmit={handleResetPassword}>
          {error && <EuiCallOut title="Error" color="danger">{error}</EuiCallOut>}
          {success && <EuiCallOut title="Success" color="success">{success}</EuiCallOut>}
          <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "請輸入Email，將發送重設鏈接到您的電子郵箱.")}>
            <EuiFieldText
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              name="email"
              required
            />
          </EuiFormRow>
          <EuiModalFooter>
            <EuiFlexGroup justifyContent="center">
              <EuiFlexItem grow={false}>
                <EuiButton type="submit" fill>{TranslateLanguage(localStorage.userLanguage, "發送重設鏈接")}</EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiModalFooter>
        </EuiForm>
      </EuiModalBody>
    </EuiModal>
  );
};

export default ResetPasswordModal;
