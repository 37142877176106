import React, { createContext, useContext, useState } from 'react';
import CustomAlert from './CustomAlert';

const AlertContext = createContext();

export const useAlert = () => useContext(AlertContext);

export const AlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ message: '', visible: false });

  const showAlert = (message) => {
    setAlert({ message, visible: true });
  };

  const hideAlert = () => {
    setAlert({ message: '', visible: false });
  };

  return (
    <AlertContext.Provider value={showAlert}>
      {children}
      {alert.visible && <CustomAlert message={alert.message} onClose={hideAlert} />}
    </AlertContext.Provider>
  );
};
