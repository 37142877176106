import React, { useState } from 'react';
import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiModalFooter, EuiButton, EuiFieldText, EuiFieldPassword, EuiForm, EuiFormRow, EuiCallOut, EuiFlexGroup, EuiFlexItem, EuiLink, EuiSpacer } from '@elastic/eui';
import { useModal } from '../ModalContext';
import '../style.css';
import {TranslateLanguage} from './KeywordTranslator';

const LoginModal = () => {
  const { isLoginModalVisible, setLoginModalVisible, setResetPasswordModalVisible } = useModal();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showVerification, setShowVerification] = useState(false);

  const closeModal = () => {
    // 清除表单内容
    setUsername('');
    setPassword('');
    setEmail('');
    setError('');
    setLoginModalVisible(false);
  };
  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    
    try {
          const response = await fetch('/api/users/login',{
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email, password }),
        });
  
        console.log("Response status:", response.status);
  
        if (!response.ok) {
          const responseData = await response.text();
          console.log("Error response data:", responseData);
  
          try {
            const errorJson = JSON.parse(responseData);
            console.log("Parsed error JSON:", errorJson);
            if (errorJson.message === "郵箱未驗證，新的驗證碼已發送.") {
              setShowVerification(true);
            }
            setError(TranslateLanguage(localStorage.userLanguage, errorJson.message) || TranslateLanguage(localStorage.userLanguage, "出錯"));
          } catch (err) {
            console.log("Error parsing JSON:", err);
            setError(responseData);
          }
        } else {
          const result = await response.json();
          // console.log("Success response data:", result);
          //sessionStorage.setItem('username', email);
          sessionStorage.setItem('user', JSON.stringify(result.userInfo));
          sessionStorage.setItem("token", result.token); // 保存JWT令牌
          // 保存 JWT 令牌和用户信息到 localStorage，确保令牌在浏览器关闭后仍然存在
          localStorage.setItem('user', JSON.stringify(result.userInfo));
          localStorage.setItem('token', result.token); // 保存 JWT 令牌
          closeModal();
          window.location.href = '/';
        }
      } catch (error) {
        console.log("Error in fetch:", error);
        setError('An error occurred: ' + error.message);
      }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
        const response = await fetch('/api/users/verify',{
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: verificationCode }),
      });

      console.log("Response status:", response.status);

      if (!response.ok) {
        const responseData = await response.text();
        console.log("Error response data:", responseData);

        try {
          const errorJson = JSON.parse(responseData);
          console.log("Parsed error JSON:", errorJson);
          setError(TranslateLanguage(localStorage.userLanguage, errorJson.message) || TranslateLanguage(localStorage.userLanguage, "出錯"));
        } catch (err) {
          console.log("Error parsing JSON:", err);
          setError(responseData);
        }
      } else {
        const result = await response.json();
        console.log("Success response data:", result);
        //sessionStorage.setItem('username', email);
        sessionStorage.setItem('user', JSON.stringify(result.userInfo));
        closeModal();
        window.location.href = '/';
      }
    } catch (error) {
      console.log("Error in fetch:", error);
      setError('An error occurred: ' + error.message);
    }
  };
  
  const openResetPasswordModal = () => {
    closeModal();
    setResetPasswordModalVisible(true);
  };

  return (
    isLoginModalVisible && (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>{TranslateLanguage(localStorage.userLanguage, "登入")}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
            <EuiForm component="form" onSubmit={showVerification ? handleVerify : handleLogin}>
            {error && <EuiCallOut title="Error" color="danger">{error}</EuiCallOut>}
            {success && <EuiCallOut title="Success" color="success">{success}</EuiCallOut>}
            {!showVerification && <>
                <EuiFormRow label="Email">
                <EuiFieldText
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    required
                />
                </EuiFormRow>
                <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "密碼")}>
                <EuiFieldPassword
                    value={password}
                    type="dual"
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    required
                />
                </EuiFormRow>
                <EuiSpacer />
                <EuiFlexGroup justifyContent="flexEnd">
                    <EuiFlexItem grow={false}>
                        <EuiLink onClick={openResetPasswordModal}>{TranslateLanguage(localStorage.userLanguage, "忘記密碼?")}</EuiLink>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiModalFooter>
                    <EuiFlexGroup justifyContent="center">
                        <EuiFlexItem grow={false}>
                            <EuiButton type="submit" fill>{TranslateLanguage(localStorage.userLanguage, "登入")}</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiModalFooter>
            </>}
            {showVerification && <>
                <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "驗證碼")}>
                <EuiFieldText
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    name="verificationCode"
                    required
                />
                </EuiFormRow>
                <EuiModalFooter>
                    <EuiFlexGroup justifyContent="center">
                        <EuiFlexItem grow={false}>
                            <EuiButton type="submit" fill>{TranslateLanguage(localStorage.userLanguage, "驗證")}</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiModalFooter>
            </>}
            </EuiForm>
        </EuiModalBody>
      </EuiModal>
    )
  );
};

export default LoginModal;
