import React, { useState } from 'react';
import { EuiModal, EuiModalHeader, EuiModalBody, EuiModalFooter, EuiButton, EuiButtonEmpty } from '@elastic/eui';
import {TranslateLanguage} from './KeywordTranslator';
import VerseModal from "../components/VerseModal"

const BibleSelectorModal = ({ isVisible, onClose, onSelect }) => {
  const [selectedBook, setSelectedBook] = useState(null); // 用戶選擇的書卷
  const [selectedChapter, setSelectedChapter] = useState(null); // 用戶選擇的章
  const [selectedVerse, setSelectedVerse] = useState(null); // 用戶選擇的節
  var userLanguage = (localStorage.userLanguage) ? localStorage.userLanguage : currentBrowserLanguage;
  localStorage.userLanguage = userLanguage;
  const [language, setLanguage] = useState(userLanguage);

  const bibleBooks = {
    '舊約': [
      { name: '創世記', 
        shortName: '創', 
        chapters: Array.from({ length: 50 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[31, 25, 24, 26, 32, 22, 24, 22, 29, 32, 
                32, 20, 18, 24, 21, 16, 27, 33, 38, 18, 
                34, 24, 20, 67, 34, 35, 46, 22, 35, 43, 
                55, 32, 20, 31, 29, 43, 36, 30, 23, 23, 
                57, 38, 34, 34, 28, 34, 31, 22, 33, 26][i] })
        }))
      },
      { name: '出埃及記', 
        shortName: '出', 
        chapters: Array.from({ length: 40 }, (_, i) => ({ 
            chapter: i + 1, 
            verses:  Array.from({ length:[22, 25, 22, 31, 23, 30, 25, 32, 35, 29, 
                10, 51, 22, 31, 27, 36, 16, 27, 25, 26, 
                36, 31, 33, 18, 40, 37, 21, 43, 46, 38, 
                18, 35, 23, 35, 35, 38, 29, 31, 43, 38][i] })
        })) 
      },
      { name: '利未記', 
        shortName: '利', 
        chapters: Array.from({ length: 27 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[17, 16, 17, 35, 19, 30, 38, 36, 24, 20, 47, 8, 59, 57, 33, 34, 16, 30, 37, 27, 24, 33, 44, 23, 55, 46, 34][i]})
        })) 
      },
      { name: '民數記', 
        shortName: '民', 
        chapters: Array.from({ length: 36 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[54, 34, 51, 49, 31, 27, 89, 26, 23, 36, 35, 16, 33, 45, 41, 50, 13, 32, 22, 29, 35, 41, 30, 25, 18, 65, 23, 31, 40, 16, 54, 42, 56, 29, 34, 13][i]}) 
        })) },
      { name: '申命記', 
        shortName: '申', 
        chapters: Array.from({ length: 34 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[46, 37, 29, 49, 33, 25, 26, 20, 29, 22, 32, 32, 18, 29, 23, 22, 20, 22, 21, 20, 23, 30, 25, 22, 19, 19, 26, 68, 29, 20, 30, 52, 29, 12][i]}) 
        })) },
      { name: '約書亞記', 
        shortName: '書', 
        chapters: Array.from({ length: 24 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[18, 24, 17, 24, 15, 27, 26, 35, 27, 43, 23, 24, 33, 15, 63, 10, 18, 28, 51, 9, 45, 34, 16, 33][i]}) 
        })) },
      { name: '士師記', 
        shortName: '士', 
        chapters: Array.from({ length: 21 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[36, 23, 31, 24, 31, 40, 25, 35, 57, 18, 40, 15, 25, 20, 20, 31, 13, 31, 30, 48, 25][i]})
        })) },
      { name: '路得記', 
        shortName: '得', 
        chapters: Array.from({ length: 4 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[22, 23, 18, 22][i]})
         })) },
      { name: '撒母耳記上', 
        shortName: '撒上', 
        chapters: Array.from({ length: 31 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[28, 36, 21, 22, 12, 21, 17, 22, 27, 27, 15, 25, 23, 52, 35, 23, 58, 30, 24, 42, 15, 23, 29, 22, 44, 25, 12, 25, 11, 31, 13][i]})
         })) },
      { name: '撒母耳記下', 
        shortName: '撒下', 
        chapters: Array.from({ length: 24 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[27, 32, 39, 12, 25, 23, 29, 18, 13, 19, 27, 31, 39, 33, 37, 23, 29, 33, 43, 26, 22, 51, 39, 25][i]})
         })) },
      { name: '列王紀上', 
        shortName: '王上', 
        chapters: Array.from({ length: 22 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[53, 46, 28, 34, 18, 38, 51, 66, 28, 29, 43, 33, 34, 31, 34, 34, 24, 46, 21, 43, 29, 53][i]})
         })) },
      { name: '列王紀下', 
        shortName: '王下', 
        chapters: Array.from({ length: 25 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[18, 25, 27, 44, 27, 33, 20, 29, 37, 36, 21, 21, 25, 29, 38, 20, 41, 37, 37, 21, 26, 20, 37, 20, 30][i]})
         })) },
      { name: '歷代志上', 
        shortName: '代上', 
        chapters: Array.from({ length: 29 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[54, 55, 24, 43, 26, 81, 40, 40, 44, 14, 47, 40, 14, 17, 29, 43, 27, 17, 19, 8, 30, 19, 32, 31, 31, 32, 34, 21, 30][i]})
         })) },
      { name: '歷代志下', 
        shortName: '代下', 
        chapters: Array.from({ length: 36 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[17, 18, 17, 22, 14, 42, 22, 18, 31, 19, 23, 16, 22, 15, 19, 14, 19, 34, 11, 37, 20, 12, 21, 27, 28, 23, 9, 27, 36, 27, 21, 33, 25, 33, 27, 23][i]})
         })) },
      { name: '以斯拉記', 
        shortName: '拉', 
        chapters: Array.from({ length: 10 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[11, 70, 13, 24, 17, 22, 28, 36, 15, 44][i]}) 
        })) },
      { name: '尼希米記', 
        shortName: '尼', 
        chapters: Array.from({ length: 13 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[11, 20, 38, 17, 19, 19, 73, 18, 38, 39, 36, 47, 31][i]})
         })) },
      { name: '以斯帖記', 
        shortName: '斯', 
        chapters: Array.from({ length: 10 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[22, 23, 15, 17, 14, 14, 10, 17, 32, 3][i]})
         })) },
      { name: '約伯記', 
        shortName: '伯', 
        chapters: Array.from({ length: 42 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[22, 13, 26, 21, 27, 30, 21, 22, 35, 22, 20, 25, 28, 22, 35, 22, 16, 21, 29, 29, 34, 30, 17, 25, 6, 14, 23, 28, 25, 31, 40, 22, 33, 37, 16, 33, 24, 41, 30, 24, 34, 17][i]})
         })) },
      { name: '詩篇', 
        shortName: '詩', 
        chapters: Array.from({ length: 150 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:
                   [6, 12, 8, 8, 12, 10, 17, 9, 20, 18,  // 第1-10篇
                    7, 8, 6, 7, 5, 11, 15, 50, 14, 9,   // 第11-20篇
                    13, 31, 6, 10, 22, 12, 14, 9, 11, 12, // 第21-30篇
                    24, 11, 22, 22, 28, 12, 40, 22, 13, 17, // 第31-40篇
                    13, 11, 5, 26, 17, 11, 9, 14, 20, 23,  // 第41-50篇
                    19, 9, 6, 7, 23, 13, 11, 11, 17, 12,   // 第51-60篇
                    8, 12, 11, 10, 13, 20, 7, 35, 36, 5,   // 第61-70篇
                    24, 20, 28, 23, 10, 12, 20, 72, 13, 19, // 第71-80篇
                    16, 8, 18, 12, 13, 17, 7, 18, 52, 17, // 第81-90篇
                    16, 15, 5, 23, 11, 13, 12, 9, 9, 5,    // 第91-100篇
                    8, 28, 22, 35, 45, 48, 43, 13, 31, 7,        // 第101-110篇
                    10, 10, 9, 8, 18, 19, 2, 29, 176, 7,    // 第111-120篇
                    8, 9, 4, 8, 5, 6, 5, 6, 8, 8,  // 第121-130篇
                    3, 18, 3, 3, 21, 26, 9, 8, 24, 13, // 第131-140篇
                    10, 7, 12, 15, 21, 10, 20, 14, 9, 6 // 第141-150篇
                  ][i]})
         })) },
      { name: '箴言', 
        shortName: '箴', 
        chapters: Array.from({ length: 31 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[33, 22, 35, 27, 23, 35, 27, 36, 18, 32, 31, 28, 25, 35, 33, 33, 28, 24, 29, 30, 31, 29, 35, 34, 28, 28, 27, 28, 27, 33, 31][i]})
         })) },
      { name: '傳道書', 
        shortName: '傳', 
        chapters: Array.from({ length: 12 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[18, 26, 22, 16, 20, 12, 29, 17, 18, 20, 10, 14][i]})
         })) },
      {
        name: '雅歌',
        shortName: '歌',
        chapters: Array.from({ length: 8 }, (_, i) => ({
          chapter: i + 1,
          verses: Array.from({ length: [17, 17, 11, 16, 16, 13, 13, 14][i] })
        }))
      },
      { name: '以賽亞書', 
        shortName: '賽', 
        chapters: Array.from({ length: 66 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[31, 22, 26, 6, 30, 13, 25, 22, 21, 34, 
                16, 6, 22, 32, 9, 14, 14, 7, 25, 6, 
                17, 25, 18, 23, 12, 21, 13, 29, 24, 33, 
                9, 20, 24, 17, 10, 22, 38, 22, 8, 31, 
                29, 25, 28, 28, 25, 13, 15, 22, 26, 11, 
                23, 15, 12, 17, 13, 12, 21, 14, 21, 22, 
                11, 12, 19, 12, 25, 24][i] })
         })) },
      { name: '耶利米書', 
        shortName: '耶', 
        chapters: Array.from({ length: 52 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[19, 37, 25, 31, 31, 30, 34, 22, 26, 25, 23, 17, 27, 22, 21, 21, 27, 23, 15, 18, 14, 30, 40, 10, 38, 24, 22, 17, 32, 24, 40, 44, 26, 22, 19, 32, 21, 28, 18, 16, 18, 22, 13, 30, 5, 28, 7, 47, 39, 46, 64, 34][i] })
         })) },
      { name: '耶利米哀歌', 
        shortName: '哀', 
        chapters: Array.from({ length: 5 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[22, 22, 66, 22, 22][i] })
         })) },
      { name: '以西結書', 
        shortName: '結', 
        chapters: Array.from({ length: 48 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[28, 10, 27, 17, 17, 14, 27, 18, 11, 22, 25, 28, 23, 23, 8, 63, 24, 32, 14, 49, 32, 31, 49, 27, 17, 21, 36, 26, 21, 26, 18, 32, 33, 31, 15, 38, 28, 23, 29, 49, 26, 20, 27, 31, 25, 24, 23, 35][i] })
         })) },
      { name: '但以理書', 
        shortName: '但', 
        chapters: Array.from({ length: 12 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[21, 49, 30, 37, 31, 28, 28, 27, 27, 21, 45, 13][i] })
         })) },
      { name: '何西阿書', 
        shortName: '何', 
        chapters: Array.from({ length: 14 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[11, 23, 5, 19, 15, 11, 16, 14, 17, 15, 12, 14, 16, 9][i] })
         })) },
      { name: '約珥書', 
        shortName: '珥', 
        chapters: Array.from({ length: 3 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[20, 32, 21][i] })
         })) },
      { name: '阿摩司書', 
        shortName: '摩', 
        chapters: Array.from({ length: 9 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[15, 16, 15, 13, 27, 14, 17, 14, 15][i] })
         })) },
      { name: '俄巴底亞書', 
        shortName: '俄', 
        chapters: Array.from({ length: 1 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[21][i] })
         })) },
      { name: '約拿書', 
        shortName: '拿', 
        chapters: Array.from({ length: 4 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[17, 10, 10, 11][i] })
         })) },
      { name: '彌迦書', 
        shortName: '彌', 
        chapters: Array.from({ length: 7 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[16, 13, 12, 13, 15, 16, 20][i] })
         })) },
      { name: '那鴻書', 
        shortName: '鴻', 
        chapters: Array.from({ length: 3 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[15, 13, 19][i] })
         })) },
      { name: '哈巴谷書', 
        shortName: '哈', 
        chapters: Array.from({ length: 3 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[17, 20, 19][i] })
         })) },
      { name: '西番雅書', 
        shortName: '番', 
        chapters: Array.from({ length: 3 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[18, 15, 20][i] })
         })) },
      { name: '哈該書', 
        shortName: '該', 
        chapters: Array.from({ length: 2 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[15, 23][i] })
         })) },
      { name: '撒迦利亞書', 
        shortName: '亞', 
        chapters: Array.from({ length: 14 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[21, 13, 10, 14, 11, 15, 14, 23, 17, 12, 17, 14, 9, 21][i] })
         })) },
      { name: '瑪拉基書', 
        shortName: '瑪', 
        chapters: Array.from({ length: 4 }, (_, i) => ({ 
            chapter: i + 1, 
            verses: Array.from({ length:[14, 17, 18, 6][i] })
         })) }
    ],
    '新約': [
      { name: '馬太福音', shortName: '太', chapters: Array.from({ length: 28 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[25, 23, 17, 25, 48, 34, 29, 34, 38, 42, 30, 50, 58, 36, 39, 28, 27, 35, 30, 34, 46, 46, 39, 51, 46, 75, 66, 20][i] }) })) },
      { name: '馬可福音', shortName: '可', chapters: Array.from({ length: 16 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[45, 28, 35, 41, 43, 56, 37, 38, 50, 52, 33, 44, 37, 72, 47, 20][i] }) })) },
      { name: '路加福音', shortName: '路', chapters: Array.from({ length: 24 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[80, 52, 38, 44, 39, 49, 50, 56, 62, 42, 54, 59, 35, 35, 32, 31, 37, 43, 48, 47, 38, 71, 56, 53][i] }) })) },
      { name: '約翰福音', shortName: '約', chapters: Array.from({ length: 21 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[51, 25, 36, 54, 47, 71, 53, 59, 41, 42, 57, 50, 38, 31, 27, 33, 26, 40, 42, 31, 25][i] }) })) },
      { name: '使徒行傳', shortName: '徒', chapters: Array.from({ length: 28 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[26, 47, 26, 37, 42, 15, 60, 40, 43, 48, 30, 25, 52, 28, 41, 40, 34, 28, 41, 38, 40, 30, 35, 27, 27, 32, 44, 31][i] }) })) },
      { name: '羅馬書', shortName: '羅', chapters: Array.from({ length: 16 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[32, 29, 31, 25, 21, 23, 25, 39, 33, 21, 36, 21, 14, 23, 33, 27][i] }) })) },
      { name: '哥林多前書', shortName: '林前', chapters: Array.from({ length: 16 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[31, 16, 23, 21, 13, 20, 40, 13, 27, 33, 34, 31, 13, 40, 58, 24][i] }) })) },
      { name: '哥林多後書', shortName: '林後', chapters: Array.from({ length: 13 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[24, 17, 18, 18, 21, 18, 16, 24, 15, 18, 33, 21, 14][i] }) })) },
      { name: '加拉太書', shortName: '加', chapters: Array.from({ length: 6 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[24, 21, 29, 31, 26, 18][i] }) })) },
      { name: '以弗所書', shortName: '弗', chapters: Array.from({ length: 6 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[23, 22, 21, 32, 33, 24][i] }) })) },
      { name: '腓立比書', shortName: '腓', chapters: Array.from({ length: 4 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[30, 30, 21, 23][i] }) })) },
      { name: '歌羅西書', shortName: '西', chapters: Array.from({ length: 4 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[29, 23, 25, 18][i] }) })) },
      { name: '帖撒羅尼迦前書', shortName: '帖前', chapters: Array.from({ length: 5 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[10, 20, 13, 18, 28][i] }) })) },
      { name: '帖撒羅尼迦後書', shortName: '帖後', chapters: Array.from({ length: 3 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[12, 17, 18][i] }) })) },
      { name: '提摩太前書', shortName: '提前', chapters: Array.from({ length: 6 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[20, 15, 16, 16, 25, 21][i] }) })) },
      { name: '提摩太後書', shortName: '提後', chapters: Array.from({ length: 4 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[18, 26, 17, 22][i] }) })) },
      { name: '提多書', shortName: '多', chapters: Array.from({ length: 3 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[16, 15, 15][i] }) })) },
      { name: '腓利門書', shortName: '門', chapters: Array.from({ length: 1 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[25][i] }) })) },
      { name: '希伯來書', shortName: '來', chapters: Array.from({ length: 13 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[14, 18, 19, 16, 14, 20, 28, 13, 28, 39, 40, 29, 25][i] }) })) },
      { name: '雅各書', shortName: '雅', chapters: Array.from({ length: 5 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[27, 26, 18, 17, 20][i] }) })) },
      { name: '彼得前書', shortName: '彼前', chapters: Array.from({ length: 5 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[25, 25, 22, 19, 14][i] }) })) },
      { name: '彼得後書', shortName: '彼後', chapters: Array.from({ length: 3 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[21, 22, 18][i] }) })) },
      { name: '約翰一書', shortName: '約一', chapters: Array.from({ length: 5 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[10, 29, 24, 21, 21][i] }) })) },
      { name: '約翰二書', shortName: '約二', chapters: Array.from({ length: 1 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[13][i] }) })) },
      { name: '約翰三書', shortName: '約三', chapters: Array.from({ length: 1 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[14][i] }) })) },
      { name: '猶大書', shortName: '猶', chapters: Array.from({ length: 1 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[25][i] }) })) },
      { name: '啟示錄', shortName: '啟', chapters: Array.from({ length: 22 }, (_, i) => ({ 
        chapter: i + 1, 
        verses: Array.from({ length:[20, 29, 22, 11, 14, 17, 17, 13, 21, 11, 19, 17, 18, 20, 8, 21, 18, 24, 21, 15, 27, 21][i] }) })) }
    ]
  };  
  
    // 點擊書卷後
    const handleBookClick = (book) => {
        setSelectedBook(book);
        setSelectedChapter(null); // 重置章
        setSelectedVerse(null);   // 重置節
      };
    
      // 點擊章後
      const handleChapterClick = (chapter) => {
        setSelectedChapter(chapter);
        setSelectedVerse(1);   // 重置節為1
      };
    
      // 點擊節後，直接提交並關閉
      const handleVerseClick = () => {
        if (selectedBook && selectedChapter) {
          const shorthand = `${selectedBook.shortName}${selectedChapter}:${selectedVerse}`;
          onSelect(shorthand); // 將結果返回給父組件
          onClose();           // 關閉模態窗口
        }
      };
    
      // 返回上一層級
      const handleBackToChapters = () => {
        setSelectedChapter(null);
      };
    
      const handleBackToBooks = () => {
        setSelectedBook(null);
        setSelectedChapter(null);
        setSelectedVerse(null);
      };

      const handleChapterUpdate = (newChapter) => {
        setSelectedChapter(newChapter);
     };

     const handleVerseUpdate = (newVerse) => {
      // console.log("Selected verse:", newVerse);
      setSelectedVerse(newVerse);
   };
    
      return (
        <>
          {isVisible && (
            <EuiModal onClose={onClose} style={{ maxWidth: 900 }}>
              {selectedBook && selectedChapter && (
                <EuiModalHeader style={{ padding: '10px', backgroundColor: '#fff', borderBottom: '1px solid #ddd' }}>
                    <EuiButtonEmpty
                      iconType="editorUndo"
                      size="m"
                      style={{
                        fontSize: '21px',
                        color: '#000',
                        fontWeight: 'bold',
                        width: '100%', // 使按钮宽度与模态框宽度一致
                        textAlign: 'left' // 按钮文本左对齐
                      }}
                      onClick={handleBackToChapters}
                    >
                      {TranslateLanguage(language, selectedBook.name)} - {selectedChapter} 章
                    </EuiButtonEmpty>
                  </EuiModalHeader>
                )}
            
            <EuiModalBody style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 200px)' }}>
              {selectedBook && selectedChapter ? (
                <VerseModal
                  book={selectedBook.shortName}
                  chapter={selectedChapter}
                  bookname={selectedBook.name}
                  selectedVerse={selectedVerse}
                  language={language}
                  onChapterChange={handleChapterUpdate}
                  onVerseChange={handleVerseUpdate}
                />
              ) : !selectedBook ? (
                <div>
                  {/* 书卷选择 */}
                  <h3>{TranslateLanguage(language, "舊約")}</h3>
                  <div className="grid-container">
                    {bibleBooks['舊約'].map((book) => (
                      <button key={book.name} onClick={() => handleBookClick(book)} className="book-button">
                        <div className="book-name">{TranslateLanguage(language, book.shortName)}</div>
                        <div className="book-subtitle">{TranslateLanguage(language, book.name)}</div>
                      </button>
                    ))}
                  </div>
                  <h3>{TranslateLanguage(language, "新約")}</h3>
                  <div className="grid-container">
                    {bibleBooks['新約'].map((book) => (
                      <button key={book.name} onClick={() => handleBookClick(book)} className="book-button">
                        <div className="book-name">{TranslateLanguage(language, book.shortName)}</div>
                        <div className="book-subtitle">{TranslateLanguage(language, book.name)}</div>
                      </button>
                    ))}
                  </div>
                </div>
              ) : (
                <div>
                  {/* 章选择 */}
                  <EuiButtonEmpty
                    iconType="editorUndo"
                    size="m"
                    style={{
                      fontSize: '21px',
                      color: '#000',
                      fontWeight: 'bold',
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#fff',
                      zIndex: 1
                    }}
                    onClick={handleBackToBooks}
                  >
                    {TranslateLanguage(language, selectedBook.name)} - {TranslateLanguage(language, "選章")}
                  </EuiButtonEmpty>
                  <br />
                  {selectedBook.chapters.map((_, i) => (
                    <button
                      key={i}
                      onClick={() => handleChapterClick(i + 1)}
                      style={{
                        padding: '10px',
                        fontSize: '16px',
                        border: '1px solid #dcdcdc',
                        borderRadius: '4px',
                        cursor: 'pointer',
                        textAlign: 'center',
                        margin: '5px',
                        width: '50px'
                      }}
                    >
                      <span style={{ fontWeight: 'bold' }}>{TranslateLanguage(language, `${i + 1}`)}</span>
                    </button>
                  ))}
                </div>
              )}
            </EuiModalBody>
            
            <EuiModalFooter>
              {selectedBook && selectedChapter && (
                <EuiButton onClick={handleVerseClick}>
                  {TranslateLanguage(language, "搜索")}
                </EuiButton>
              )}
              <EuiButton onClick={onClose}>{TranslateLanguage(language, "取消")}</EuiButton>
            </EuiModalFooter>
          </EuiModal>          
          )}
        </>
      );
    };

export default BibleSelectorModal;
