import React from 'react';
import '../style.css';

const CustomAlert = ({ message, onClose }) => {
  return (
    <>
      <div className="custom-alert-overlay"></div>
      <div className="custom-alert">
        <p>{message}</p>
        <button className="btn-confirm" onClick={onClose}>OK</button>
      </div>
    </>
  );
};

export default CustomAlert;