import React, { useState } from 'react';
import { EuiModal, EuiModalBody, EuiModalHeader, EuiModalHeaderTitle, EuiModalFooter, EuiButton, EuiFieldText, EuiFieldPassword, EuiForm, EuiFormRow, EuiCallOut, EuiFlexGroup, EuiFlexItem } from '@elastic/eui';
import { useModal } from '../ModalContext';
import '../style.css';
import {TranslateLanguage} from './KeywordTranslator';
import { useAlert } from './AlertProvider';

const RegisterModal = () => {
  const { isRegisterModalVisible, setRegisterModalVisible } = useModal();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [showVerification, setShowVerification] = useState(false);
  const showAlert = useAlert();

  const closeModal = () => {
    // 清除表单内容
    setUsername('');
    setPassword('');
    setConfirmPassword('');
    setEmail('');
    setError('');
    setRegisterModalVisible(false);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    // 前端密码验证
    // const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
    const passwordRegex = /^(?=.*[A-Za-z])(?=.*[\d!#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~])[A-Za-z\d!#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]{6,}$/;
    if (!passwordRegex.test(password)) {
      setError(TranslateLanguage(localStorage.userLanguage, "密碼至少6位，同時包含字母和數字"));
      return;
    }
    if (password !== confirmpassword) {
      setError(TranslateLanguage(localStorage.userLanguage, "密碼不匹配"));
      return;
    }
    // 电子邮件地址验证
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError(TranslateLanguage(localStorage.userLanguage, "請輸入有效的Email地址"));
      return;
    }
    setError('');
    setSuccess('');

    try {
        //const response = await fetch('http://localhost:5000/api/users/signup', {
        const response = await fetch('/api/users/signup', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ username, password, email }),
        });

        // console.log("Response status:", response.status);

        if (!response.ok) {
            const responseData = await response.text();
            // console.log("Error response data:", responseData);

            try {
              const errorJson = JSON.parse(responseData);
              // console.log("Parsed error JSON:", errorJson);
              setError(TranslateLanguage(localStorage.userLanguage, errorJson.message) || TranslateLanguage(localStorage.userLanguage, "出錯"));
            } catch (err) {
              //console.log("Error parsing JSON:", err);
              setError(TranslateLanguage(localStorage.userLanguage, responseData));
            }
        } else {
            const result = await response.json();
            // console.log("Success response data:", result);
            closeModal();
            showAlert(TranslateLanguage(localStorage.userLanguage, "註冊成功"));
            // setShowVerification(true);
            // setSuccess(result.message || 'User registered successfully! Please check your email for the verification code.');
        }
        } catch (error) {
        //console.log("Error in fetch:", error);
        setError(TranslateLanguage(localStorage.userLanguage, "出錯：") + error.message);
    }
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');

    try {
      //const response = await fetch('http://localhost:5000/api/users/verify', {
      const response = await fetch('/api/users/verify', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, code: verificationCode }),
      });

      console.log("Response status:", response.status);

      if (!response.ok) {
        const responseData = await response.text();
        console.log("Error response data:", responseData);

        try {
          const errorJson = JSON.parse(responseData);
          console.log("Parsed error JSON:", errorJson);
          setError(errorJson.message || 'An error occurred');
        } catch (err) {
          console.log("Error parsing JSON:", err);
          setError(TranslateLanguage(localStorage.userLanguage, responseData));
        }
      } else {
        const result = await response.json();
        // console.log("Success response data:", result);
        setSuccess(result.message || TranslateLanguage(localStorage.userLanguage, "郵箱驗證成功"));
        //sessionStorage.setItem('username', email);
        sessionStorage.setItem('user', JSON.stringify(result.userInfo));
        closeModal();
        window.location.href = '/';
      }
    } catch (error) {
      //console.log("Error in fetch:", error);
      setError(TranslateLanguage(localStorage.userLanguage, "出錯：") + error.message);
    }
  };

  return (
    isRegisterModalVisible && (
      <EuiModal onClose={closeModal}>
        <EuiModalHeader>
          <EuiModalHeaderTitle>{TranslateLanguage(localStorage.userLanguage, "用戶註冊")}</EuiModalHeaderTitle>
        </EuiModalHeader>
        <EuiModalBody>
          {/* <EuiForm component="form" onSubmit={handleRegister}>
            {error && <EuiCallOut title="Error" color="danger">{error}</EuiCallOut>}
            {success && <EuiCallOut title="Success" color="success">{success}</EuiCallOut>}
            <EuiFormRow label="Username">
              <EuiFieldText
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                name="username"
                required
              />
            </EuiFormRow>
            <EuiFormRow label="Password">
              <EuiFieldPassword
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                name="password"
                required
              />
            </EuiFormRow>
            <EuiFormRow label="Confirm Password">
              <EuiFieldPassword
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                name="confirmpassword"
                required
              />
            </EuiFormRow>
            <EuiFormRow label="Email">
              <EuiFieldText
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                name="email"
                required
              />
            </EuiFormRow>
            {isCodeSent && (
              <EuiFormRow label="Verification Code">
                <EuiFieldText
                  value={verificationCode}
                  onChange={(e) => setVerificationCode(e.target.value)}
                  name="verificationCode"
                  required
                />
              </EuiFormRow>
            )}
            {!isCodeSent && (
              <EuiButton onClick={handleSendCode} fill>Send Verification Code</EuiButton>
            )}
            {isCodeSent && (
              <EuiModalFooter>
                <EuiButton type="submit" fill>Sign Up</EuiButton>
              </EuiModalFooter>
            )}
          </EuiForm> */}
          <EuiForm component="form" onSubmit={showVerification ? handleVerify : handleRegister}>
            {error && <EuiCallOut title="Error" color="danger">{error}</EuiCallOut>}
            {success && <EuiCallOut title="Success" color="success">{success}</EuiCallOut>}
            {!showVerification && <>
                <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "用戶名")}>
                <EuiFieldText
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    name="username"
                    required
                />
                </EuiFormRow>
                <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "密碼")}>
                <EuiFieldPassword
                    value={password}
                    type="dual"
                    onChange={(e) => setPassword(e.target.value)}
                    name="password"
                    required
                />
                </EuiFormRow>
                <EuiFormRow label={TranslateLanguage(localStorage.userLanguage, "確認密碼")}>
                <EuiFieldPassword
                    value={confirmpassword}
                    type="dual"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    name="confirmpassword"
                    required
                />
                </EuiFormRow>
                <EuiFormRow label="Email">
                <EuiFieldText
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    required
                />
                </EuiFormRow>
                <EuiModalFooter>
                    <EuiFlexGroup justifyContent="center">
                        <EuiFlexItem grow={false}>
                            <EuiButton type="submit" fill>{TranslateLanguage(localStorage.userLanguage, "註冊")}</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiModalFooter>               
            </>}
            {showVerification && <>
                <EuiFormRow label="Verification Code">
                <EuiFieldText
                    value={verificationCode}
                    onChange={(e) => setVerificationCode(e.target.value)}
                    name="verificationCode"
                    required
                />
                </EuiFormRow>
                <EuiModalFooter>
                    <EuiFlexGroup justifyContent="center">
                        <EuiFlexItem grow={false}>
                            <EuiButton type="submit" fill>Verify</EuiButton>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiModalFooter>   
            </>}
          </EuiForm>
        </EuiModalBody>
      </EuiModal>
    )
  );
};

export default RegisterModal;
