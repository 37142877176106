import React, { useState, useEffect } from 'react';
import {
  EuiLoadingSpinner,
  EuiPanel,
  EuiText,
  EuiSelect,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiTextArea,
  EuiComboBox
} from '@elastic/eui';
import '../style.css';
import PlaylistModal from "../components/PlaylistModal"

const SongManagement = () => {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedSong, setSelectedSong] = useState(null);
  const [sortKey, setSortKey] = useState('title'); // 默认按标题排序
  const [languageOptions, setLanguageOptions] = useState([]);
  const [tempoOptions, setTempoOptions] = useState([]);
  const [officialOptions, setOfficialOptions] = useState([]);
  const [resultMessage, setResultMessage] = useState(''); // 用于显示提示信息
  const [isNewSong, setIsNewSong] = useState(false);  // 用于判断是否是新增诗歌
  const [searchTerm, setSearchTerm] = useState(''); // 用于存储搜索关键词
  const [totalCount, setTotalCount] = useState(0);
  const [showPlaylistModal, setShowModal] = useState(false);
  const [modalProps, setModalProps] = useState({
    userId: '',
    songId: '',
    videoURL: '',
    type: '',
    engineId: '',
    title: '',
    label: '',
    playlisttype: ''
  });

  const fetchAllSongs = async () => {
    const apiURL = process.env.REACT_APP_MasterApiURL;
    const apiKey = process.env.REACT_APP_APIKey;
    const pageSize = 1000;
    let currentPage = 1;
    let hasMoreData = true;

    let fetchedSongs = [];

    try {
      while (hasMoreData) {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer search-" + apiKey
          },
          body: JSON.stringify({
            query: "",
            filters: { "all": [{ "doctype": "詩歌" }] },
            page: { size: pageSize, current: currentPage },
            sort: { id: "asc" }
          })
        });

        if (response.ok) {
          const data = await response.json();
          const songs = data.results.map(song => {
            let length = song.length.raw || "";
          
            // 检查 length 是否为小数格式，并进行转换
            if (!isNaN(parseFloat(length)) && isFinite(length)) {
              length = convertDecimalToTime(parseFloat(length));  // 将小数转换为 hh:mm:ss
            }
          
            return {
              id: song.id.raw || "",
              title: song.title.raw || "",
              link: song.link.raw || "",
              doctype: song.doctype.raw || "",
              publisher: song.publisher.raw || "",
              description: song.description.raw || "",
              language: song.language.raw || "",
              length,  // 将转换后的或原始的 length 值赋给对象
              tempo: song.tempo.raw || "",
              key: song.key.raw || "",
              tag: song.tag.raw || "",
              official: song.official.raw || "",
              verselist: song.verselist.raw || "",
            };
          });

          fetchedSongs = [...fetchedSongs, ...songs];

          if (songs.length < pageSize) {
            hasMoreData = false;
          } else {
            currentPage++;
          }
        } else {
          console.error("Error fetching songs: ", response.statusText);
          return;
        }
      }

      setSongs(fetchedSongs);
      console.log("All songs fetched:", fetchedSongs);
      setTotalCount(fetchedSongs.length);  // 更新总条数
      // 从所有诗歌中提取唯一的 Language、Tempo 和 Official 选项
      const uniqueLanguages = Array.from(new Set(fetchedSongs.map(song => song.language))).filter(Boolean);
      const uniqueTempos = Array.from(new Set(fetchedSongs.map(song => song.tempo))).filter(Boolean);
      const uniqueOfficials = Array.from(new Set(fetchedSongs.map(song => song.official))).filter(Boolean);

      // 设置下拉菜单的选项
      setLanguageOptions(uniqueLanguages.map(lang => ({ label: lang })));
      setTempoOptions(uniqueTempos.map(tempo => ({ label: tempo })));
      setOfficialOptions(uniqueOfficials.map(official => ({ label: official })));
    } catch (error) {
      console.error("Error while fetching songs: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllSongs();
  }, []);


  // 提取ID中的数字部分
  const extractIdNumber = (id) => {
    const match = id.match(/\d+$/);  // 匹配最后的数字部分
    return match ? parseInt(match[0], 10) : id;
  };
  // 获取当前最大ID + 1
  const getNextId = () => {
    const maxId = songs.reduce((max, song) => {
      const idParts = song.id.split('|'); // 将ID按 "|" 分割
      const numericId = parseInt(idParts[1], 10); // 取得后面的数字部分
  
      // 比较获取最大ID
      return Math.max(max, numericId);
    }, 0);
  
    return maxId + 1;  // 返回最大ID + 1
  };

  // 根据搜索关键词过滤列表
  const filteredSongs = songs.filter((song) =>
    song.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 按位数排序
  const sortSongs = (songs, key) => {
    if (key === 'id') {
      return [...songs].sort((a, b) => {
        const idA = extractIdNumber(a.id);
        const idB = extractIdNumber(b.id);
        return idA - idB;
      });
    }
    // 如果是按title排序，直接按字母排序
    return [...songs].sort((a, b) => (a[key] > b[key] ? 1 : -1));
  };

  // 对过滤后的结果进行排序
  const sortedSongs = sortSongs(filteredSongs, sortKey);

  // 计算时长，将 hh:mm:ss 或 mm:ss 转换为小数（小时数）
    function calculateLength(timeString) {
        const parts = timeString.split(':').map(Number);
        let hours = 0;
        let minutes = 0;
        let seconds = 0;

        // 根据输入的长度判断是 mm:ss 还是 hh:mm:ss
        if (parts.length === 2) {
            // 如果输入格式为 mm:ss
            minutes = parts[0];
            seconds = parts[1];
        } else if (parts.length === 3) {
            // 如果输入格式为 hh:mm:ss
            hours = parts[0];
            minutes = parts[1];
            seconds = parts[2];
        }

        // 转换为小时
        const totalHours = hours + (minutes / 60) + (seconds / 3600);
        return totalHours * 2.5;  // 根据您提供的比例进行转换
    }

    // 将小数转换为 hh:mm:ss 格式
    function convertDecimalToTime(decimal) {
        const totalSeconds = Math.round(decimal * 3600 / 2.5); // 将小数转换为总秒数
        const hours = Math.floor(totalSeconds / 3600);          // 计算小时
        const remainingSeconds = totalSeconds % 3600;
        const minutes = Math.floor(remainingSeconds / 60);      // 计算分钟
        const seconds = remainingSeconds % 60;                  // 计算秒数

        // 如果小时为 0，只显示 mm:ss 格式
        if (hours === 0) {
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }

        // 否则显示完整的 hh:mm:ss 格式
        return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    }

    // 解析时间字符串 hh:mm:ss 或 mm:ss
    function parseTimeString(timeString) {
        const parts = timeString.split(':').map(Number);

        if (parts.length === 2) {
            // 处理 mm:ss 格式
            return {
                hours: 0,
                minutes: parts[0],
                seconds: parts[1],
            };
        } else if (parts.length === 3) {
            // 处理 hh:mm:ss 格式
            return {
                hours: parts[0],
                minutes: parts[1],
                seconds: parts[2],
            };
        } else {
            throw new Error("Invalid time format");
        }
    }

  const handleSongSelect = (song) => {
      setSelectedSong(song); // 设置选中的歌曲
      setIsNewSong(false);  // 保存成功后，取消新增标识
      setResultMessage(''); // 清空 resultMessage
    };
  // 点击"新建诗歌"按钮时调用
  const handleNewSong = () => {
    const newSong = {
      id: getNextId().toString(),  // ID为最大值+1
      title: '',
      link: '',
      publisher: '',
      description: '',
      language: '',
      length: '',
      tempo: '',
      key: '',
      tag: '',
      official: '',
      verselist: '',
    };
    setSelectedSong(newSong);  // 将新诗歌设置为选中的诗歌
    setIsNewSong(true);  // 设置为新增模式
    setResultMessage('');  // 清空结果提示
  };
  // 保存修改的函数
  async function handleSaveSong() {
      // 设置初始提示信息
      setResultMessage("Start updating song...");

        // 验证红框内的项目是否为空
      if (!selectedSong.title || !selectedSong.link || !selectedSong.length || !selectedSong.language || !selectedSong.tempo || !selectedSong.description || !selectedSong.publisher) {
        setResultMessage('Error: All fields must be filled.');
        return;  // 阻止保存操作
      }

      // 验证 link 是否为有效 URL
      const urlPattern = new RegExp('^(https?:\\/\\/)?' + // 协议
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|(\\d{1,3}\\.){3}\\d{1,3})' + // 域名或IP
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // 端口和路径
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // 查询字符串
        '(\\#[-a-z\\d_]*)?$', 'i'); // 片段标识符

      if (!urlPattern.test(selectedSong.link)) {
        setResultMessage('Error: The Song Link must be a valid URL.');
        return;  // 阻止保存操作
      }
        // 如果是新增的诗歌（通过 isNewSong 判断），检查 Song Link 是否已存在
      if (isNewSong) {
        const linkExists = songs.some(song => song.link === selectedSong.link);
        if (linkExists) {
          setResultMessage('Error: The Song Link already exists. Please enter a unique link.');
          return;  // 阻止保存操作
        }
      }
      // 获取表单输入值（使用 EUI 组件的值获取方法）
      const title = selectedSong.title;
      const link = selectedSong.link;
      const songId = extractIdNumber(selectedSong.id);

      // 获取 EuiComboBox 选中的值 (假设是单选)
      const language = selectedSong.language;
      const tempo = selectedSong.tempo;
      const official = selectedSong.official;
      // 解析 length (时间) 字段
      let length;
      try {
          const timeString = selectedSong.length;  // 获取时间字符串
          const { hours, minutes, seconds } = parseTimeString(timeString);    // 使用 parseTimeString 解析时间
          length = calculateLength(`${hours}:${minutes}:${seconds}`).toFixed(6); // 计算并转换为小数
      } catch (error) {
          setResultMessage("Invalid time format. Please enter a valid time (mm:ss or hh:mm:ss).");
          return;  // 时间格式无效，直接返回
      }

      // 构建新的歌曲对象
      const newSong = {
          id: songId,
          doctype: "詩歌",
          title: title,
          link: link,
          publisher: selectedSong.publisher,
          description: selectedSong.description,
          language: language,  // 从 EuiComboBox 获取的值
          length: length,  // 已转换为小数格式
          tempo: tempo,  // 从 EuiComboBox 获取的值
          key: selectedSong.key,
          tag: selectedSong.tag,
          official: official,  // 从 EuiComboBox 获取的值
          verselist: selectedSong.verselist,
          // versecontent: document.getElementById('versecontent').value
      };

      // 调用上传函数并处理响应
      const uploadSuccess = await uploadSong(newSong);

      // 更新提示信息
      if (uploadSuccess) {
          newSong.id = "ctb-worship-tc|"+songId;
          newSong.length = selectedSong.length;
          setSelectedSong(newSong);  // 使用返回的最新数据更新当前选中歌曲
          setResultMessage("Song updated successfully!");
          setSongs((prevSongs) => {
            const songExists = prevSongs.some(song => song.id === newSong.id);
            if (!songExists) {
              return [...prevSongs, newSong];  // 如果不存在，添加新诗歌
            } else {
              // 如果已存在，替换更新后的歌曲
              return prevSongs.map(song => 
                song.id === newSong.id ? newSong : song
              );
            }
          });
          if (isNewSong){
            setTotalCount(totalCount+1);  // 更新总条数
          }
          setIsNewSong(false);  // 保存成功后，取消新增标识
      } else {
          setResultMessage("Song update failed.");
      }
  };

  async function uploadSong(song) {
    const postURL = process.env.REACT_APP_SongDocURL;
    const postKey = process.env.REACT_APP_PostKey;
      try {
          const response = await fetch(postURL, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": postKey
              },
              body: JSON.stringify([song])  // 上传时将歌曲对象封装为数组
          });

          if (response.ok) {
              console.log("Song uploaded: ", await response.json());
              return true;  // 上传成功
          } else {
              console.error("Error uploading song: ", response.statusText);
              return false;  // 上传失败
          }
      } catch (error) {
          console.error("Error while uploading song: ", error);
          return false;  // 处理异常
      }
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <EuiLoadingSpinner size="xl" />
        <EuiText color="default" style={{ marginTop: '10px' }}>
          正在加载中...
        </EuiText>
      </div>
    );
  }

  const handleAddplaylistClick = () => {
    const user = sessionStorage.getItem('user');
    if (user) {
      // 如果用户已登录，显示 PlaylistModal 组件
      setModalProps({
        userId: JSON.parse(user).id, // 假设 user 对象里有 id 字段
        songId: selectedSong.id,
        videoURL: selectedSong.link,
        type: selectedSong.doctype, 
        engineId: selectedSong.id, 
        title: selectedSong.title,
        label: 'v',
        playlisttype: 'radio'
      });
      setShowModal(true);
    } else {
      return (
        <EuiText color="danger">
          请登录管理员用户操作。
        </EuiText>
      );
    }
  };

  return (
    <EuiFlexGroup>
      {/* 左侧歌曲列表 */}
      <EuiFlexItem grow={false} className="song-list-container">
        <EuiPanel paddingSize="s">
          <EuiFormRow label={`诗歌列表 共${totalCount}條`}>
            <EuiSelect
              options={[
                { value: 'title', text: '按标题排序' },
                { value: 'id', text: '按ID排序' }
              ]}
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}
            />
          </EuiFormRow>

          {/* 搜索框 */}
          <input
            type="text"
            placeholder="搜索诗歌..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />

          <ul className="song-list">
            {/* {sortSongs(songs, sortKey).map((song) => ( */}
            {sortedSongs.map((song) => (
              <li key={song.id} onClick={() => handleSongSelect(song)}>
                {song.title} (ID: {song.id})
              </li>
            ))}
          </ul>
        </EuiPanel>
      </EuiFlexItem>

      {/* 右侧详细信息编辑 */}
      <EuiFlexItem>
        <EuiFlexGroup direction="column">
        {selectedSong ? (
          <EuiPanel paddingSize="l">
            <h3>编辑诗歌: {selectedSong.title}</h3>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="Song ID">
                  <EuiFieldText value={extractIdNumber(selectedSong.id)} readOnly />
                </EuiFormRow>

                <EuiFormRow label="Song Title">
                  <EuiFieldText
                    value={selectedSong.title}
                    onChange={(e) => setSelectedSong({ ...selectedSong, title: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Song Link">
                  <EuiFieldText
                    value={selectedSong.link}
                    append={
                      <EuiButton href={selectedSong.link} target="_blank">
                        Open Link
                      </EuiButton>
                    }
                    onChange={(e) => setSelectedSong({ ...selectedSong, link: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Publisher">
                  <EuiFieldText
                    value={selectedSong.publisher}
                    onChange={(e) => setSelectedSong({ ...selectedSong, publisher: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Description">
                  <EuiTextArea
                    value={selectedSong.description}
                    onChange={(e) => setSelectedSong({ ...selectedSong, description: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Language">
                  <EuiComboBox
                    options={languageOptions}
                    selectedOptions={selectedSong.language ? [{ label: selectedSong.language }] : []}
                    onChange={(selected) => {
                      const newLanguage = selected.length ? selected[0].label : '';
                      setSelectedSong({ ...selectedSong, language: newLanguage });
                    }}
                    onCreateOption={(newOption) => {
                      setLanguageOptions([...languageOptions, { label: newOption }]);
                      setSelectedSong({ ...selectedSong, language: newOption });
                    }}
                  />
                </EuiFormRow>
              </EuiFlexItem>

              <EuiFlexItem>
              <EuiFormRow label="Length (hh:mm:ss)">
                <EuiFieldText
                    value={selectedSong.length}
                    onChange={(e) => {
                        const input = e.target.value;  // 获取用户输入的值
                        setSelectedSong({ ...selectedSong, length: input });  // 允许用户自由输入，不进行判断
                        }}
                />
                </EuiFormRow>

                <EuiFormRow label="Tempo">
                  <EuiComboBox
                    options={tempoOptions}
                    selectedOptions={selectedSong.tempo ? [{ label: selectedSong.tempo }] : []}
                    onChange={(selected) => {
                      const newTempo = selected.length ? selected[0].label : '';
                      setSelectedSong({ ...selectedSong, tempo: newTempo });
                    }}
                    onCreateOption={(newOption) => {
                      setTempoOptions([...tempoOptions, { label: newOption }]);
                      setSelectedSong({ ...selectedSong, tempo: newOption });
                    }}
                  />
                </EuiFormRow>

                <EuiFormRow label="Key">
                  <EuiFieldText
                    value={selectedSong.key}
                    onChange={(e) => setSelectedSong({ ...selectedSong, key: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Tags">
                  <EuiFieldText
                    value={selectedSong.tag}
                    onChange={(e) => setSelectedSong({ ...selectedSong, tag: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="Official">
                  <EuiComboBox
                    options={officialOptions}
                    selectedOptions={selectedSong.official ? [{ label: selectedSong.official }] : []}
                    onChange={(selected) => {
                      const newOfficial = selected.length ? selected[0].label : '';
                      setSelectedSong({ ...selectedSong, official: newOfficial });
                    }}
                    onCreateOption={(newOption) => {
                      setOfficialOptions([...officialOptions, { label: newOption }]);
                      setSelectedSong({ ...selectedSong, official: newOption });
                    }}
                  />
                </EuiFormRow>

                <EuiFormRow label="Verselist">
                  <EuiTextArea
                    value={selectedSong.verselist}
                    onChange={(e) => setSelectedSong({ ...selectedSong, verselist: e.target.value })}
                  />
                </EuiFormRow>
              </EuiFlexItem>
            </EuiFlexGroup>

            {/* 显示 resultMessage 的位置 */}
            <div style={{ textAlign: 'center' }}>
              <EuiText color="danger" style={{ marginBottom: '10px' }}>{resultMessage}</EuiText>
              <EuiFlexGroup justifyContent="center" gutterSize="s">
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={handleSaveSong}>保存修改</EuiButton>
                </EuiFlexItem>
                
                <EuiFlexItem grow={false}>
                  <EuiButton onClick={handleAddplaylistClick}>加入电台</EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
            </div>
          </EuiPanel>
        ) : (
          <EuiText>请选择一首诗歌以查看详细信息。</EuiText>
        )}
          <EuiFlexItem>
            {/* 新建诗歌按钮 */}
            <div style={{ textAlign: 'center' }}>
              <EuiButton style={{ width: '100px' }} onClick={handleNewSong}>新建诗歌</EuiButton>
            </div>
          </EuiFlexItem>
        </EuiFlexGroup>
      </EuiFlexItem>
      {/* 渲染 PlaylistModal */}
      {showPlaylistModal && (
        <PlaylistModal
          userId={modalProps.userId}
          songId={modalProps.songId}
          videoURL={modalProps.videoURL}
          title={modalProps.title}
          type={modalProps.type}
          engineId={modalProps.engineId}
          label={modalProps.label}
          playlisttype={modalProps.playlisttype}
          closeModal={() => setShowModal(false)}
        />
      )}
    </EuiFlexGroup>
  );
};

export default SongManagement;
