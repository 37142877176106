import React, { useState, useEffect } from 'react';
import {
  EuiLoadingSpinner,
  EuiPanel,
  EuiText,
  EuiSelect,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFieldText,
  EuiFormRow,
  EuiTextArea,
  EuiComboBox
} from '@elastic/eui';
import '../style.css';

const VerseManagement = () => {
  const [verses, setverses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedverse, setSelectedverse] = useState(null);
  const [sortKey, setSortKey] = useState('title'); // 默认按标题排序
  const [resultMessage, setResultMessage] = useState(''); // 用于显示提示信息
  const [searchTerm, setSearchTerm] = useState(''); // 用于存储搜索关键词
  const [totalCount, setTotalCount] = useState(0);

  const fetchAllverses = async () => {
    const apiURL = process.env.REACT_APP_MasterApiURL;
    const apiKey = process.env.REACT_APP_APIKey;
    const pageSize = 1000;
    let currentPage = 1;
    let hasMoreData = true;
    let lastChapterNumber = 1;

    let fetchedverses = [];

    try {
      while (lastChapterNumber <= 66 && hasMoreData) {
        const response = await fetch(apiURL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": "Bearer search-" + apiKey
          },
          body: JSON.stringify({
            query: "",
            filters: {
                "all": [
                    { "doctype": "經文" },
                    { "book_number": lastChapterNumber.toString() }  // 转换为字符串
                ]
            },
            page: { size: pageSize, current: currentPage },
            sort: { id: "asc" }
          })
        });

        if (response.ok) {
          const data = await response.json();
          const verses = data.results.map(verse => {
            return {
              id: verse.id.raw || "",
              title: verse.title.raw || "",
              booknumber: verse.book_number.raw || "",
              doctype: verse.doctype.raw || "",
              chaptercodename: verse.chapter_code_name.raw || "",
              chaptername: verse.chapter_name.raw || "",
              chapternumber: verse.chapter_number.raw || "",
              chapterversenumber: verse.chapter_verse_number.raw || "",
              versenumber: verse.verse_number.raw || "",
              verse: verse.verse.raw || "",
              verse_id: verse.verse_id.raw || "",
              tag: verse.tag.raw || ""
            };
          });

          fetchedverses = [...fetchedverses, ...verses];

          if (verses.length < pageSize) {
             lastChapterNumber++;  // 继续查询下一章
			 currentPage = 1;  // 重置分页
          } else {
            currentPage++;
          }
        } else {
          console.error("Error fetching verses: ", response.statusText);
          return;
        }
      }

      setverses(fetchedverses);
      console.log("All verses fetched:", fetchedverses);
      setTotalCount(fetchedverses.length);  // 更新总条数
    } catch (error) {
      console.error("Error while fetching verses: ", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAllverses();
  }, []);


  // 提取ID中的数字部分
  const extractIdNumber = (id) => {
    const match = id.match(/\d+$/);  // 匹配最后的数字部分
    return match ? parseInt(match[0], 10) : id;
  };
  // 获取当前最大ID + 1
  const getNextId = () => {
    const maxId = verses.reduce((max, verse) => {
      const idParts = verse.id.split('|'); // 将ID按 "|" 分割
      const numericId = parseInt(idParts[1], 10); // 取得后面的数字部分
  
      // 比较获取最大ID
      return Math.max(max, numericId);
    }, 0);
  
    return maxId + 1;  // 返回最大ID + 1
  };

  // 根据搜索关键词过滤列表
  const filteredverses = verses.filter((verse) =>
    verse.title.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // 按位数排序
  const sortverses = (verses, key) => {
    if (key === 'id') {
      return [...verses].sort((a, b) => {
        const idA = extractIdNumber(a.id);
        const idB = extractIdNumber(b.id);
        return idA - idB;
      });
    }
    // 如果是按title排序，直接按字母排序
    return [...verses].sort((a, b) => (a[key] > b[key] ? 1 : -1));
  };

  // 对过滤后的结果进行排序
  const sortedverses = sortverses(filteredverses, sortKey);

  const handleverseselect = (verse) => {
      setSelectedverse(verse); // 设置选中的歌曲
      setResultMessage(''); // 清空 resultMessage
    };
  
  // 保存修改的函数
  async function handleSaveverse() {
      // 设置初始提示信息
      setResultMessage("Start updating verse...");
    //   const verseId = extractIdNumber(selectedverse.id);
    //   selectedverse.verse_id = verseId;

        // 验证红框内的项目是否为空
      if (!selectedverse.title || !selectedverse.booknumber || !selectedverse.chaptercodename || !selectedverse.chaptername || !selectedverse.chapternumber || !selectedverse.chapterversenumber || !selectedverse.versenumber || !selectedverse.verse) {
        setResultMessage('Error: All fields must be filled.');
        return;  // 阻止保存操作
      }

      // 获取表单输入值（使用 EUI 组件的值获取方法）
      const verseId = extractIdNumber(selectedverse.id);

      // 构建新的歌曲对象
      const newverse = {
          id: verseId,
          doctype: "經文",
          title: selectedverse.title ,
          book_number: selectedverse.booknumber,
          chapter_code_name: selectedverse.chaptercodename,
          chapter_name: selectedverse.chaptername,
          chapter_number: selectedverse.chapternumber,
          chapter_verse_number: selectedverse.chapterversenumber,
          verse_number: selectedverse.versenumber,
          verse: selectedverse.verse,
          verse_id: selectedverse.verse_id,
          tag: selectedverse.tag,
      };

      // 调用上传函数并处理响应
      const uploadSuccess = await uploadverse(newverse);

      // 更新提示信息
      if (uploadSuccess) {
          newverse.id = "ctb-bible-chinese|"+verseId;
          setSelectedverse(newverse);  // 使用返回的最新数据更新当前选中歌曲
          setResultMessage("verse updated successfully!");
          setverses((prevverses) => {
            const verseExists = prevverses.some(verse => verse.id === newverse.id);
            if (!verseExists) {
              return [...prevverses, newverse];  // 如果不存在，添加新經文
            } else {
              // 如果已存在，替换更新后的歌曲
              return prevverses.map(verse => 
                verse.id === newverse.id ? newverse : verse
              );
            }
          });
      } else {
          setResultMessage("verse update failed.");
      }
  };

  async function uploadverse(verse) {
     const postURL = process.env.REACT_APP_BibleDocURL;
     const postKey = process.env.REACT_APP_PostKey;
      try {
          const response = await fetch(postURL, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json",
                  "Authorization": postKey
              },
              body: JSON.stringify([verse])  // 上传时将歌曲对象封装为数组
          });

          if (response.ok) {
              console.log("verse uploaded: ", await response.json());
              return true;  // 上传成功
          } else {
              console.error("Error uploading verse: ", response.statusText);
              return false;  // 上传失败
          }
      } catch (error) {
          console.error("Error while uploading verse: ", error);
          return false;  // 处理异常
      }
  }

  if (loading) {
    return (
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <EuiLoadingSpinner size="xl" />
        <EuiText color="default" style={{ marginTop: '10px' }}>
          正在加载中...
        </EuiText>
      </div>
    );
  }

  return (
    <EuiFlexGroup>
      {/* 左侧歌曲列表 */}
      <EuiFlexItem grow={false} className="song-list-container">
        <EuiPanel paddingSize="s">
          <EuiFormRow label={`經文列表 共${totalCount}節`}>
            <EuiSelect
              options={[
                { value: 'title', text: '按标题排序' },
                { value: 'id', text: '按ID排序' }
              ]}
              value={sortKey}
              onChange={(e) => setSortKey(e.target.value)}
            />
          </EuiFormRow>

          {/* 搜索框 */}
          <input
            type="text"
            placeholder="搜索經文..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: '10px', padding: '5px', width: '100%' }}
          />

          <ul className="song-list">
            {/* {sortverses(verses, sortKey).map((verse) => ( */}
            {sortedverses.map((verse) => (
              <li key={verse.id} onClick={() => handleverseselect(verse)}>
                {verse.title} (ID: {verse.id})
              </li>
            ))}
          </ul>
        </EuiPanel>
      </EuiFlexItem>

      {/* 右侧详细信息编辑 */}
      <EuiFlexItem>
        {selectedverse ? (
          <EuiPanel paddingSize="l">
            <h3>编辑經文: {selectedverse.title}</h3>

            <EuiFlexGroup>
              <EuiFlexItem>
                <EuiFormRow label="verse ID">
                  <EuiFieldText value={extractIdNumber(selectedverse.id)} readOnly />
                </EuiFormRow>

                <EuiFormRow label="verse Title">
                  <EuiFieldText
                    value={selectedverse.title}
                    onChange={(e) => setSelectedverse({ ...selectedverse, title: e.target.value })}
                  />
                </EuiFormRow>

                <EuiFormRow label="booknumber">
                  <EuiFieldText
                    value={selectedverse.booknumber} readOnly
                  />
                </EuiFormRow>

                <EuiFormRow label="verse">
                  <EuiFieldText
                    value={selectedverse.verse} readOnly
                  />
                </EuiFormRow>

                {/* <EuiFormRow label="verse ID">
                  <EuiFieldText
                    value={selectedverse.verse_id} readOnly
                  />
                </EuiFormRow> */}

                <EuiFormRow label="tag">
                  <EuiFieldText
                    value={selectedverse.tag} readOnly
                  />
                </EuiFormRow>
                
              </EuiFlexItem>
              <EuiFlexItem>

                <EuiFormRow label="chapter name">
                    <EuiFieldText
                        value={selectedverse.chaptername} readOnly
                    />
                </EuiFormRow>

                <EuiFormRow label="chapter code name">
                  <EuiFieldText
                    value={selectedverse.chaptercodename} readOnly
                  />
                </EuiFormRow>

                <EuiFormRow label="chapter verse number">
                  <EuiFieldText
                    value={selectedverse.chapterversenumber} readOnly
                  />
                </EuiFormRow>

                <EuiFormRow label="chapter number">
                  <EuiFieldText
                    value={selectedverse.chapternumber} readOnly
                  />
                </EuiFormRow>

                <EuiFormRow label="verse number">
                  <EuiFieldText
                    value={selectedverse.versenumber} readOnly
                  />
                </EuiFormRow>
             
              </EuiFlexItem>
            </EuiFlexGroup>

            {/* 显示 resultMessage 的位置 */}
            <div style={{ textAlign: 'center' }}>
              <EuiText color="danger" style={{ marginBottom: '10px' }}>{resultMessage}</EuiText>
              <EuiButton onClick={handleSaveverse}>保存修改</EuiButton>
            </div>
          </EuiPanel>
        ) : (
          <EuiText>请选择一首經文以查看详细信息。</EuiText>
        )}
      </EuiFlexItem>
    </EuiFlexGroup>
  );
};

export default VerseManagement;