import React, { useEffect }  from "react";
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client'; // 从 react-dom/client 中导入 createRoot
import App from "./App";
import axios from 'axios';
import { useAlert } from './components/AlertProvider';
import { AlertProvider } from './components/AlertProvider';

const container = document.getElementById('root');  // 获取挂载的 DOM 节点
const root = createRoot(container);  // 创建一个 root

const AxiosInterceptorSetup = () => {
    const showAlert = useAlert();

    useEffect(() => {
        // 检查是否有在 localStorage 中存储的 token 和 user
        const token = localStorage.getItem('token');
        const user = localStorage.getItem('user');

        if (token && user) {
            // 如果存在，则将其复制到 sessionStorage
            sessionStorage.setItem('token', token);
            sessionStorage.setItem('user', user);
        }
        // 全局配置Axios的拦截器
        const responseInterceptor = axios.interceptors.response.use(
            (response) => {
                // 正常响应
                return response;
            },
            (error) => {
                // 捕获 401 错误，Token 过期
                if (error.response && error.response.status === 401) {
                    showAlert("会话已过期，请重新登录。");
                    // 你可以跳转到登录页面
                    localStorage.removeItem('username');
                    sessionStorage.removeItem('user');
                    sessionStorage.removeItem('token');
                    window.location.href = '/';
                }
                return Promise.reject(error);
            }
        );

        const requestInterceptor = axios.interceptors.request.use(
            (config) => {
                const token = sessionStorage.getItem('token');
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
                return config;
            },
            (error) => {
                return Promise.reject(error);
            }
        );

        // 清理拦截器
        return () => {
            axios.interceptors.response.eject(responseInterceptor);
            axios.interceptors.request.eject(requestInterceptor);
        };
    }, [showAlert]);

    return null;
};


// ReactDOM.render(<App />, document.getElementById("root"));
root.render(
    <React.StrictMode>
        <AlertProvider>
            <AxiosInterceptorSetup />
            <App />
        </AlertProvider>
    </React.StrictMode>
  );
