import React ,{useState, useEffect} from 'react';
import TranslateInput, {TranslateLanguage, getMatchingVerse} from '../components/KeywordTranslator';
import '../style.css';

import {
    EuiText,
    EuiSelect, 
    EuiSpacer,
    EuiFlyoutBody,
    EuiFlyoutHeader,
    EuiFlyoutFooter,
    EuiFlexGroup,
    EuiFlexItem,
    EuiTitle,
    EuiButton
  } from '@elastic/eui';

function Comment(props) {
    const [result, setResult] = useState({title:"", link: ""});
    var book = props.book;
    var bookName = props.bookname;
    var language = props.language;
    var bookNumber = ("00" + book).substr(("00" + book).length-2);
    var chapter = props.chapter
    var chapterLeadingZero = book == "19" ? "000" : "00"
    var chapterNumber = chapterLeadingZero + chapter
    chapterNumber =  chapterNumber.substr(chapterNumber.length-chapterLeadingZero.length);
    const apiURL = process.env.REACT_APP_MasterApiURL;
    const apiKey = process.env.REACT_APP_APIKey;
    var verseNumber = props.versenumber;

    useEffect(()=>{
        loadComment();
    },[])

    function extractContent(stringHTML) {
      var content =""
      var span = document.createElement('span');
      span.innerHTML = stringHTML;
      var contextExtracted = span.getElementsByTagName("table")[0];
      if(contextExtracted){
        contextExtracted.getElementsByTagName("u")[0].remove();
        contextExtracted.querySelectorAll("span, p, strong")
        .forEach(el => el.textContent.trim() === "" && el.parentNode.removeChild(el));
        content = contextExtracted.innerHTML;  
        // // processing find on page
        // var stripContent =  content.replace(/<\/?span[^>]*>/g,"")
        // var verseTrailerIndex = stripContent.indexOf(verseNumber + "】");
        // var verseHeadIndex = stripContent.indexOf("【", verseTrailerIndex - 5 );
        // var foundVerseString = stripContent.substring(verseHeadIndex,verseTrailerIndex+2);
        // content = stripContent.replace(foundVerseString, "<div id='matchverse'><em>" + foundVerseString + "</em></div>");
        // processing find on page
        var matchVerse = getMatchingVerse(book, chapter);
        var stripContent =  content.replace(/<\/?span[^>]*>/g,"")
        var verseHeadIndex = stripContent.indexOf("【" + matchVerse + verseNumber);
        if(verseHeadIndex >= 0){
        var verseTrailerIndex = stripContent.indexOf("】", verseHeadIndex  );
        var foundVerseString = stripContent.substring(verseHeadIndex,verseTrailerIndex+1);
        content = stripContent.replace(foundVerseString, "<div id='matchverse'><em>" + foundVerseString + "</em></div>");
        }
      }
      
      return content;
    };
        
    const loadComment = async () => {
        await fetch(apiURL,{
            method:"POST",
            headers:{
                "Content-Type":"application/json, charset=UTF-8",
                "Authorization":  "Bearer search-" + apiKey
            },
            body:JSON.stringify({
                query:"",
                filters: {"all": [{doctype : "註解"}, {book: bookNumber}, {chapter: chapterNumber}]}, 
                page:{size: 1000},
                sort:{id: "asc"}
            })
        })
        .then(function(response){
            return(response.json())
        })
        .then(function(data){
            var results = JSON.parse(JSON.stringify(data))
            var resultText = results.results[0].title.raw
            var resultLink = results.results[0].link.raw
      
            resultText = resultText.split("壹、內容綱要").join("<p>壹、內容綱要<p>")
            resultText = resultText.split("貳、逐節詳解").join("<p>貳、逐節詳解<p>")
            resultText = resultText.split("叁、靈訓要義").join("<p>叁、靈訓要義<p>")
            resultText = resultText.split("〔呂振中譯〕").join("<br>〔呂振中譯〕<br>")
            resultText = resultText.split("〔原文字義〕").join("<br>〔原文字義〕<br>")
            resultText = resultText.split("〔文意註解〕").join("<br>〔文意註解〕<br>")
            resultText = resultText.split("〔話中之光〕").join("<br>〔話中之光〕<br>")
            resultText = resultText.split("【").join("<p>【")
            resultText = resultText + "<p>。。。（前往原始網站查看更多）"

            //setResult({title: resultText, link: resultLink});
            loadCommentLocal(resultLink, resultText);     
        })
    }

    const loadCommentLocal = async (filePath, resultText) => {
      var resultLink = filePath;
      filePath = filePath.replace("https://www.ccbiblestudy.org/New Testament","");
      filePath = filePath.replace("https://www.ccbiblestudy.org/Old Testament","");
      filePath = window.location.origin  + '/ccbiblestudy' + filePath;

      // console.log('Requesting:', filePath);
   
      await fetch(filePath, {
        method: 'GET', // 默认是 GET，可以省略
        mode: 'cors',  // 如果跨域访问，设置为 'cors'
        credentials: 'same-origin', // 如果需要携带 cookies，改为 'include'
        headers: {
          'Content-Type': 'text/html', // 确保内容类型是文本/HTML
        }
      })
      .then(function(response){
          return(response.text())
      })
      .then(function(data){
          var results = data;
          var finalResult = extractContent(results);
          resultText = finalResult != "" ? finalResult : resultText;
          setResult({title: resultText, link: resultLink})  ;   
          // var elmnt = document.getElementById("matchverse");
          // elmnt && elmnt.scrollIntoView();
      })
  }

    useEffect(() => {
      const elmnt = document.getElementById("matchverse");
      if (elmnt) {
          elmnt.scrollIntoView();
      }
  }, [result]); // Add dependencies to re-run when `result` updates

    return (
        <>
          <EuiFlyoutHeader hasBorder>
              <EuiTitle size="m">
                {/* <h2 id="flyoutTitle">{bookName} {chapter}  {TranslateLanguage(language,"經文註釋")}</h2> */}
                <h2 id="flyoutTitle">{ TranslateLanguage(language,bookName)}  {TranslateLanguage(language,chapter)} { TranslateLanguage(language,"經文註釋")} </h2>
              </EuiTitle>
          </EuiFlyoutHeader>
          <EuiFlyoutBody>

            <EuiText><span dangerouslySetInnerHTML={{__html: TranslateLanguage(language,result.title)}} /></EuiText>

          </EuiFlyoutBody>
          <EuiFlyoutFooter>
              <EuiFlexGroup justifyContent="spaceBetween">
                <EuiFlexItem grow={false}>
                </EuiFlexItem>
                <EuiFlexItem grow={false}> 
                  <EuiButton href={result.link} target="_blank" fill>
                  {TranslateLanguage(language,"連結來源")}
                  </EuiButton>
                </EuiFlexItem>
              </EuiFlexGroup>
          </EuiFlyoutFooter>
         </>
    )
}

export default Comment
